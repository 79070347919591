import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Grid from "@mui/material/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import RemoveIcon from "@material-ui/icons/Remove";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import { numberWithCommas, numberWithCommasINR } from "../../../actions/createPositionActions";
import { FormControl, Paper, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemIcon, ListItemText } from "@material-ui/core";
import { MenuProps } from "../../CommonFIle/util";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import ConfirmDialog from "../../../Components/ConfirmDialog";

export default function Visiting({ sentCategory, sentDataToParent, doctorData, data, category, centerData, centerList, primaryCenter }) {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    formControl: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [visibleFee, setVisibleFee] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState([]);
  const [centerOptions, setCenterOptions] = useState([]);
  const [table, setTable] = useState([]);
  const [selectedRows, setSelectedRows] = useState([])
  const [commercialItem, setCommercialItem] = useState([])
  const initialFormData = {
    doctorId: "",
    professionalfeepermonth: "0",
    additionalfeename: "",
    additionalfeevalue: " ",
  };
  const [fixed, setFixed] = useState(initialFormData);
  const makeVisible = () => {
    setVisible(true);
    setVisibleFee(true);
  };
  const makeVisiblefalse = () => {
    fixed.additionalfeename = "";
    fixed.additionalfeevalue = "";
    // setadditionalfee("");
    // setadditionalfeename("");
    setVisible(false);
    setVisibleFee(false);
  };
  useEffect(() => {
    let centerDataValue = [...centerData]
    if (primaryCenter && primaryCenter !== undefined && primaryCenter.name) {
      if (!centerDataValue.find(ele => ele == primaryCenter.name)) {
        centerDataValue.push(primaryCenter.name)
      }
    } else {
      if (data.centerNames && data.centerNames !== undefined) {
        if (!centerDataValue.find(ele => ele == data.centerNames)) {
          centerDataValue.push(data.centerNames)
        }
      }
      if (data.doctorId && data.businessunit && data.businessunit !== undefined) {
        if (!centerDataValue.find(ele => ele == data.businessunit)) {
          centerDataValue.push(data.businessunit)
        }
      }
    }
    setCenterOptions(centerDataValue);
    setSelectedCenter(centerDataValue)
    sentDataToParent(table)
  }, [data, centerData, table, primaryCenter]);

  useEffect(() => {
    if (doctorData && doctorData.VisitingParamter) {
      updateCommercials(doctorData.VisitingParamter)
    }
  }, [doctorData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFixed({ ...fixed, [name]: value });
    console.log(event.target.value, "sssss")
    if (event.target.value === "") {
      sentCategory({ ...fixed, [name]: fixed });
    } else {
      sentCategory({ ...fixed, [name]: event.target.value });
    }
  };
  const parentItemChangeCenter = (event) => {
    const value = event.target.value;
    setSelectedCenter(value);
  };
  const deleteRow = (row) => {
    var data = [...table]
    var ids = []
    row.map((elem) => {
      ids.push(elem.tableData.id)
    })
    data = data.filter((z, i) => {
      if (!ids.includes(i)) {
        return z
      }
    })
    setTable(data);
  }
  const addTableData = () => {
    let fixedDataArray = []
    for (let g = 0; g < selectedCenter.length; g++) {
      if (table.find(elem => elem.Center === selectedCenter[g])) {
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Data already present for the Center - " + selectedCenter[g],
        });
        return false
      }
      if (fixed.professionalfeepermonth === '' || fixed.professionalfeepermonth === '0' || fixed.professionalfeepermonth === 0) {
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Please Enter Professional Fee / Month",
        });
        return false
      }
      let fixedData = { ...fixed, Center: selectedCenter[g] }
      fixedDataArray.push(fixedData);
    }
    setTable((prevItems) => [...prevItems, ...fixedDataArray]);
    cancelUpdation()
  }
  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const FG = <p>Professional Fee / Month<span style={{ color: "red" }} > *</span></p>;
  const addname = <p>Additional Fee Name<span style={{ color: "red" }} > *</span></p>;
  const addval = <p>Additonal Fee value<span style={{ color: "red" }} > *</span></p>;

  const handleSelectionRows = async (rows) => {
    setSelectedRows(rows)
  }

  const updateCommercials = async (commercials) => {
    await setTable((prevItems) => [...prevItems, ...commercials])
  }

  const editCommercial = async (row) => {
    var item = row[0]
    await setCommercialItem(row)
    fixed.professionalfeepermonth = item.professionalfeepermonth
    fixed.additionalfeename = item.additionalfeename
    fixed.additionalfeevalue = item.additionalfeevalue
    for (let i = 0; i < centerList.length; i++) {
      const element = centerList[i];
      if (item.Center == element.name) {
        await setSelectedCenter([item.Center]);
        await setFixed({ ...fixed });
        await sentCategory({ ...fixed });
      }
    }
    if (fixed.additionalfeevalue !== '' && fixed.additionalfeevalue !== '0' && fixed.additionalfeevalue !== 0) {
      await makeVisible()
    }
  }

  const UpdateTableData = async () => {
    let fixedDataArray = [...table]
    let tableIndex = table.findIndex((row) => commercialItem && commercialItem[0] && commercialItem[0].tableData.id === row.tableData.id)
    for (let g = 0; g < selectedCenter.length; g++) {
      if (table.find(elem => elem.Center === selectedCenter[g] && commercialItem[0].tableData.id !== elem.tableData.id)) {
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Data already present for the Center - " + selectedCenter[g],
        });
        return false
      }
      if (fixed.professionalfeepermonth === '' || fixed.professionalfeepermonth === '0' || fixed.professionalfeepermonth === 0) {
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Please Enter Professional Fee / Month",
        });
        return false
      }
      fixedDataArray[tableIndex].Center = selectedCenter[g]
      fixedDataArray[tableIndex].additionalfeename = fixed.additionalfeename
      fixedDataArray[tableIndex].additionalfeevalue = fixed.additionalfeevalue
      fixedDataArray[tableIndex].professionalfeepermonth = fixed.professionalfeepermonth
      // let fixedData = { ...fixed, Center: selectedCenter[g] }
      // fixedDataArray.push(fixedData);
    }
    console.log(fixedDataArray, 'fixedDataArray')
    await setTable(fixedDataArray);
    await cancelUpdation();
  }

  const cancelUpdation = async () => {
    await setCommercialItem([])
    await setSelectedRows([])
    await setSelectedCenter([])
    await makeVisiblefalse()
    await setFixed(initialFormData)
  }

  return (
    <>
      <CssBaseline />
      <Container>
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "18px", marginTop: "10px" }}
        >
          <FormControl className={classes.formControl} style={{ marginTop: "0px" }}>
            <label >Select Center</label>
            <Select
              autoFocus
              multiple
              labelId="mutiple-select-label"
              value={selectedCenter}
              onChange={parentItemChangeCenter}
              renderValue={(selectedCenter) => selectedCenter.join(", ")}
              MenuProps={MenuProps} style={{ marginTop: "0px" }}
            >
              {centerOptions && centerOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedCenter.indexOf(option) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
            <TextField
              variant="outlined"
              name="professionalfeepermonth"
              label={FG}
              value={
                fixed.professionalfeepermonth
                  ? fixed.professionalfeepermonth
                  : ""
              }
              // onKeyUp={fixedPercentageOrAmount}
              onChange={handleChange}
              onInput={(e) => onlyNumbers(e)}
              fullWidth
              size="medium"
            />
            {/* <TextField
              variant="outlined"
              name="professionalfeepermonth"
              label={FG}
              // label="Professional Fee / Month"
              value={fixed.professionalfeepermonth ? numberWithCommas(fixed.professionalfeepermonth) : ""}
              onChange={handleChange}
              fullWidth
            /> */}

            {!visibleFee ? (
              <Tooltip title="Add" aria-label="Add">
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={makeVisible}
                  style={{ marginTop: "15px" }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            ) : (
              // <Button
              //   variant="contained"
              //   color="primary"
              //   style={{
              //     height: "50%",
              //     marginTop: "10px",
              //     padding: "3px",
              //     width: "30%",
              //   }}
              //   startIcon={<AddIcon />}
              //   fullWidth
              //   onClick={makeVisible}
              // >
              //   ADD
              // </Button>
              <Tooltip title="Remove" aria-label="Remove">
                <Fab
                  color="secondary"
                  aria-label="edit"
                  onClick={makeVisiblefalse}
                  style={{ marginTop: "15px" }}
                >
                  <RemoveIcon />
                </Fab>
              </Tooltip>
              // <Button
              //   variant="contained"
              //   color="secondary"
              //   style={{
              //     height: "50%",
              //     marginTop: "10px",
              //     padding: "3px",
              //     width: "30%",
              //   }}
              //   startIcon={<RemoveIcon />}
              //   fullWidth
              //   onClick={makeVisiblefalse}
              // >
              //   REMOVE
              // </Button>
            )}
            {
              commercialItem && commercialItem.length ?
                <Button variant="contained" color="primary" style={{ width: "fit-content", marginTop: "13px", marginLeft: "20px" }} startIcon={<UpgradeIcon />} onClick={UpdateTableData}>Update</Button>
                :
                <Button variant="contained" color="primary" style={{ width: "fit-content", marginTop: "13px", marginLeft: "20px" }} startIcon={<AddIcon />} onClick={addTableData}>ADD</Button>
            }
          </Grid>
          <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
            {visible ? (
              <>
                <TextField
                  variant="outlined"
                  name="additionalfeename"
                  label={addname}
                  // label="Additional Fee Name"
                  value={fixed.additionalfeename}
                  onChange={handleChange}
                  fullWidth
                  style={{ marginBottom: "10px" }}
                />

                <TextField
                  variant="outlined"
                  name="additionalfeevalue"
                  label={addval}
                  // label="Additonal Fee value"
                  value={fixed.additionalfeevalue ? numberWithCommas(fixed.additionalfeevalue) : ""}
                  onChange={handleChange}
                  onInput={(e) => onlyNumbers(e)}
                  fullWidth
                  style={{ marginBottom: "10px" }}
                />
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2px", marginBottom: "20px" }}>
          {table && table.length > 0 ? (
            <Paper>
              {/* <Paper className={tables.root}> */}
              <MaterialTable
                columns={[
                  {
                    title: "Center",
                    field: "Center"
                  },
                  {
                    title: "Professional Fees /Month",
                    field: "professionalfeepermonth",
                    render: (row) => (<div>{row.professionalfeepermonth ? "Rs. " + numberWithCommas(parseFloat(row.professionalfeepermonth).toFixed()) : "---"}</div>),
                  },
                  {
                    title: "Additional Fees Name",
                    field: "additionalfeename",
                    render: (row) => <div>{row.additionalfeename ? row.additionalfeename : "---"}</div>
                  },
                  {
                    title: "Additional Fees Value",
                    field: "additionalfeevalue",
                    render: (row) => (<div>{row.additionalfeevalue && row.additionalfeevalue !== null && parseFloat(row.additionalfeevalue) > 0 ? "Rs. " + numberWithCommas(parseFloat(row.additionalfeevalue).toFixed()) : "---"}</div>),
                  }
                ]}
                actions={[
                  {
                    icon: "delete",
                    tooltip: "Delete",
                    iconProps: {
                      style: {
                        fontSize: "24px",
                        color: "#f55151",
                      },
                    },
                    // onClick: (event, row) => deleteRow(row),
                    onClick: (event, row) => {
                      setConfirmDialog({
                        isOpen: true,
                        title:
                          "Deleting Mg-Detail may result in some features unresponsive ?",
                        subtitle:
                          "Are you sure you want to delete this record ?",
                        onConfirm: () => {
                          deleteRow(row);
                          setConfirmDialog({ isOpen: false });
                        },
                      });
                    },
                  },
                  {
                    icon: "edit",
                    tooltip: "Edit",
                    iconProps: {
                      style: {
                        fontSize: "24px",
                        color: "#f55151",
                      },
                    },
                    onClick: (event, row) => {
                      if (row.length === 1) {
                        editCommercial(row)
                      }
                      console.log("event in row", row)
                    },
                    hidden: (selectedRows.length !== 1),
                  },
                ]}
                data={table}
                options={{
                  // filtering: true,
                  selection: true,
                  sorting: true,
                  paging: false,
                  search: false,
                  headerStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px",
                    fontSize: 12,
                  },
                  cellStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px"
                  },
                  rowStyle: {
                    fontSize: 12,
                  },
                }}
                onSelectionChange={handleSelectionRows}
                title={<TableTitle text="Estimations" />}
              />
            </Paper>
          ) : (
            ""
          )}
        </Grid>
      </Container>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}