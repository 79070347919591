import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../../images/logo-light.jpg";
import { login } from "../../actions/authActions";
import Message from "../../Components/Message";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { APIURL } from "../../CommomMethods/Fetch";
function LoginPage({ location }) {
  toast.configure();
  // const auth = useSelector(state => state.auth)
  let history = useHistory();
  const initialForgotInputState = {
    User_Email: "",
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [opendialog, setOpenDialog] = useState(false);
  const [forgottenmail, setForgottenMail] = useState(initialForgotInputState);
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { loading, error, user } = userLogin;
  // console.log(user, "luser");
  const redirect = location.search ? location.search.split("=")[1] : "/";

  // if the user is already logged in.
  useEffect(() => {
    console.log(user && user.user && user.user.roles, "user && user.user && user.user.roles")
    if ((user && user.user && user.user.roles) == null) {
      history.push("/");
    }
    else if ((user && user.user && user.user.roles) === 0) {
      history.push("/Template");
    } else if (user && user.user && user.user.roles === 4) {
      history.push("/doctor-dashboard");
      // history.push("/Estimation");
    }
    else if (user && user.user && user.user.roles === 9) {
      history.push("/CFODashBoard");
    }
    else if ((user && user.user && user.user.roles) !== 0 && (user && user.user && user.user.roles) !== 4) {
      history.push("/bh-dashboard");
    }

    // else if (user && user.user && user.user.roles === 2) {
    //   history.push("/bh-dashboard");
    // } else if (user && user.user && user.user.roles === 3) {
    //   history.push("/hr-dashboard");
    // } else if (user && user.user && user.user.roles === 5) {
    //   history.push("/CeoAgreement");
    // } else if (user && user.user && user.user.roles === 6) {
    //   history.push("/FinanceDashboard");
    // } else if (user && user.user && user.user.roles == 8) {
    //   history.push("/DrmTemplate");
    // } else if (user && user.user && user.user.roles == 7) {
    //   history.push("/payout-dasboard");
    // }else if (user && user.user && user.user.roles == 10) {
    //   history.push("/ipHead");
    // } else if (user && user.user && user.user.roles == 0) {
    //   history.push("/Template");
    // } 
    else history.push("/");
  }, [history, user, redirect]);

  //PayoutDashboardNew

  const submitHandler = (e) => {
    e.preventDefault();
    // dispatch login
    // const encryptedEmail = btoa(email);
    const encryptedPassword = btoa(password);
    // console.log("encr",encryptedEmail,encryptedPassword)
    dispatch(login(email, encryptedPassword));
  };

  // const dashboard = () => {
  // 	let path = `dashboard`;
  // 	history.push(path);
  // }
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleEmailChange = (event) => {
    const { name, value } = event.target;
    setForgottenMail({ ...forgottenmail, [name]: value });
  };

  const CheckMail = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(forgottenmail.User_Email)) {
      alert("Please enter valid email address. eg. xyz@example.com");

      return null;
    }
    axios
      .post(APIURL() + "users//forgotEmail", forgottenmail)
      .then((res) => {
        if (res.data && res.data.message && res.data.message == 'Mail Sent') {
          handleCloseDialog()
          toast.success('Mail sent to your email address, kindly check your mail box.');
          setForgottenMail({ ...forgottenmail, User_Email: '' });
        } else {
          toast.error(res.data.message);
        }
      });
  };

  return (
    <>
      <div id="loginPage">
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <div className="login100-more">
                <div className="row">
                  <div className="col-md-12">
                    <div className="signup">
                      <div className="signup-connect">
                        <img
                          src={logo2}
                          className="img-responsive"
                          alt="logo"
                        />
                        <br></br>
                        <br></br>
                        <div className="hospName">
                          <span className="hospTagline">Welcome to </span>
                          <span className="hospHeader">CLOUDNINE PORTAL</span>
                          <br />
                        </div>
                      </div>
                      <div class="signup-classic">
                        <span className="hospTagline">
                          Please login to access the Portal
                        </span>

                        {error && <Message variant="danger">{error}</Message>}
                        <form class="form" onSubmit={submitHandler}>
                          <fieldset class="username">
                            {/* {...register('username', { required: true })} */}
                            <input
                              type="email"
                              name="username"
                              className="form-control inputField"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Username"
                              required
                            />
                          </fieldset>
                          <fieldset class="password">
                            <input
                              type="password"
                              name="password"
                              className="form-control inputField"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              id="exampleInputPassword1"
                              placeholder="Password"
                              required
                            />
                          </fieldset>
                          <Link
                            to="#"
                            variant="body2"
                            style={{ textDecoration: "none", color: "green" }}
                            onClick={() => {
                              handleClickOpenDialog();
                            }}
                          >
                            Forgot password?
                          </Link>
                          <br />
                          <button type="submit" class="sub-btn" disabled={email.trim().length===0 || password.trim().length===0}>
                            Login
                          </button>
                          {/* <a href="/SignUp">Register?</a> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={opendialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Forgot Password?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Give your e-mail id here, So that we can send you a verification
            link and enable you to reset your password.
          </DialogContentText>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              CheckMail();
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              required
              name="User_Email"
              onChange={handleEmailChange}
              value={forgottenmail.User_Email}
            />
            <div style={{ float: "right", marginTop: "30px" }}>
              <Button type="submit" color="primary">
                Send
              </Button>

              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LoginPage;
