import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@mui/material/Grid";
import { FormControl, Paper } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputAdornment from "@mui/material/InputAdornment";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { numberWithCommas, numberWithCommasINR } from "../../../actions/createPositionActions";
import MaterialTable from "material-table";
import { Button, Checkbox, ListItemIcon, ListItemText, Typography } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import { MenuProps } from "../../CommonFIle/util";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmDialog from "../../../Components/ConfirmDialog";

export default function Fixed({ sentCategory, category, ipCalData, opCalData, data, doctorData, centerData, sentDataToParent, primaryCenter }) {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const initialFormData = {
    doctorId: "",
    minguranteepermonth: "",
    mgperiod: '',
    settlementperiod: "",
    settlementterm: "",
    settlementpaymentperiod: "",
  };
  const initialFormtState = {
    Center: "",
    minguranteepermonth: "",
    mgperiod: "",
    settlementterm: "",
    settlementperiod: "",
    settlementpaymentperiod: "",
    doctorId: "",
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    formControl: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  const [fixedMGData, setfixedMGData] = useState({
    initialFormtState,
  });
  const [fixed, setFixed] = useState(initialFormData);
  const [mgperiod, setMgperiod] = useState('');
  const [table, setTable] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState([]);
  const [centerOptions, setCenterOptions] = useState([]);
  const handleChange = (event) => {

    console.log(event, "EVENT");
    const { name, value } = event.target;
    if (name == "settlementperiod") {
      setFixed({ ...fixed, ["settlementpaymentperiod"]: value, [name]: value });
      sentCategory({ ...fixed, ["settlementpaymentperiod"]: event.target.value, [name]: event.target.value });
    } else {
      setFixed({ ...fixed, [name]: value });
      sentCategory({ ...fixed, [name]: event.target.value });
    }
  };
  useEffect(() => {
    let centerDataValue = [...centerData]
    if (primaryCenter && primaryCenter !== undefined && primaryCenter.name) {
      if (!centerDataValue.find(ele => ele == primaryCenter.name)) {
        centerDataValue.push(primaryCenter.name)
      }
    } else {
      if (data.centerNames && data.centerNames !== undefined) {
        if (!centerDataValue.find(ele => ele == data.centerNames)) {
          centerDataValue.push(data.centerNames)
        }
      }
      if (data.doctorId && data.businessunit && data.businessunit !== undefined) {
        if (!centerDataValue.find(ele => ele == data.businessunit)) {
          centerDataValue.push(data.businessunit)
        }
      }
    }
    console.log(centerDataValue, "centerDataValue---------")
    setMgperiod(category.mgperiod);
    setCenterOptions(centerDataValue);
    setSelectedCenter(centerDataValue)
    sentDataToParent(table)
  }, [category.mgperiod, data, centerData, table, primaryCenter]);
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const addTableData = () => {
    let fixedMGDataArray = []
    fixedMGData.minguranteepermonth = fixed.minguranteepermonth
    fixedMGData.settlementterm = fixed.minguranteepermonth
    fixedMGData.settlementperiod = fixed.settlementperiod
    fixedMGData.settlementpaymentperiod = fixed.settlementpaymentperiod
    fixedMGData.doctorId = 0
    if (fixed.minguranteepermonth === '') {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please Enter Minimum Guarentee /Month",
      });
      return false
    }
    if (fixed.mgperiod === '' || fixed.mgperiod === 0) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please Enter MG Period in Months",
      });
      return false
    }
    if (fixed.settlementperiod === '') {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please Select Settlement Calculation Period",
      });
      return false
    }
    // if (fixed.settlementterm === '') {
    //   setConfirmDialog({
    //     isOpen: true,
    //     title: "Alert",
    //     subtitle: "Please Select Settlement Term",
    //   });
    //   return false
    // }
    if (fixed.settlementpaymentperiod === '') {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please Select Settlement Payment Period",
      });
      return false
    }
    for (let g = 0; g < selectedCenter.length; g++) {
      if (table.find(elem => elem.Center === selectedCenter[g])) {
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Data already present for the Center - " + selectedCenter[g],
        });
        return false
      }
      let fixedData = { ...fixedMGData, Center: selectedCenter[g], mgperiod: mgperiod }
      console.log(fixedData, "fixedMGDataArray--------")
      fixedMGDataArray.push(fixedData);
    }
    console.log(fixedMGDataArray, fixed, mgperiod, "fixedMGDataArray--------")
    setTable((prevItems) => [...prevItems, ...fixedMGDataArray]);
  }
  const parentItemChangeCenter = (event) => {
    const value = event.target.value;
    setSelectedCenter(value);
  };
  const deleteRow = (row) => {
    var data = [...table]
    var ids = []
    row.map((elem) => {
      ids.push(elem.tableData.id)
    })
    data = data.filter((z, i) => {
      if (!ids.includes(i)) {
        return z
      }
    })
    setTable(data);
  }
  const MG = <p>Minimum Guarentee /Month<span style={{ color: "red" }} > *</span></p>;

  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );

  useEffect(() => {
    if (doctorData && doctorData.fixedMg) {
      updateCommercials(doctorData.fixedMg)
    }
  }, [doctorData]);

  const updateCommercials = async (commercials) => {
    await setTable((prevItems) => [...prevItems, ...commercials])
  }
  return (
    <>
      <CssBaseline />
      <Container>
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "18px", marginTop: "10px" }}
        >
          <FormControl className={classes.formControl} style={{ marginTop: "0px" }}>
            <label >Select Center</label>
            <Select
              autoFocus
              multiple
              labelId="mutiple-select-label"
              value={selectedCenter}
              onChange={parentItemChangeCenter}
              renderValue={(selectedCenter) => selectedCenter.join(", ")}
              MenuProps={MenuProps} style={{ marginTop: "0px" }}
            >
              {centerOptions && centerOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedCenter.indexOf(option) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
            <TextField
              variant="outlined"
              name="minguranteepermonth"
              label={MG}
              value={
                fixed.minguranteepermonth
                  ? numberWithCommas(fixed.minguranteepermonth)
                  : ""
              }
              // onKeyUp={fixedPercentageOrAmount}
              onChange={handleChange}
              onInput={(e) => onlyNumbers(e)}
              fullWidth
              size="medium"
              style={{ width: "100%", marginBottom: "18px" }}
            />
            <FormControl
              variant="outlined"
              style={{ width: "100%", marginBottom: "18px" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Select Settlement Calculation Period <i
                  class="fa fa-asterisk"
                  style={{
                    color: "red",
                    fontSize: "6px",
                    marginLeft: "5px",
                    verticalAlign: "super",
                    // marginTop: "-150px",
                  }}
                  aria-hidden="true"
                ></i>
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="settlementperiod"
                value={fixed.settlementperiod}
                onChange={handleChange}
                label=" Select Settlement Calculation Period"
              >
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Quaterly">Quaterly</MenuItem>
                <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                <MenuItem value="Yearly">Yearly</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>

            <TextField
              variant="outlined"
              label={"MG Period In Months"}
              value={mgperiod}
              // onKeyUp={fixedPercentageOrAmount}
              onChange={handleChange}
              name="mgperiod"
              required
              onInput={(e) => onlyNumbers(e)}
              fullWidth
              size="medium"
              style={{ width: "100%", marginBottom: "18px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span>In Months</span>
                  </InputAdornment>
                ),
              }}
            />
            {/* <TextField
              id="outlined-basic"
              label="MG Period In Years"
              variant="outlined"
              fullWidth
              style={{ marginBottom: "18px" }}
              name="mgperiod"
              value={fixed.mgperiod}
              autoComplete="off"
              disabled
            /> */}
            {/* <TextField
              id="outlined-basic"
              label="MG Period In Years"
              fullWidth
              variant="outlined"
              name="mgperiod"
              value={fixed.mgperiod}
              onChange={handleChange}
              style={{ marginBottom: "18px" }}
            /> */}
            {/* <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /> */}
            {/* <TextField
              id="outlined-basic"
              label="Settlement Term"
              fullWidth
              variant="outlined"
              name="settlementterm"
              onChange={handleChange}
              value={fixed.settlementterm}
            /> */}



            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Settlement Payment Period <i
                  class="fa fa-asterisk"
                  style={{
                    color: "red",
                    fontSize: "6px",
                    marginLeft: "5px",
                    verticalAlign: "super",
                    // marginTop: "-150px",
                  }}
                  aria-hidden="true"
                ></i>
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={fixed.settlementpaymentperiod}
                disabled
                name="settlementpaymentperiod"
                onChange={handleChange}
                label="  Select Settlement Payment Period"
              >
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Quaterly">Quaterly</MenuItem>
                <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                <MenuItem value="Yearly">Yearly</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ width: "100%", marginBottom: "18px" }}>
              {/* <InputLabel id="demo-simple-select-outlined-label">
                Settlement Term<i
                  class="fa fa-asterisk"
                  style={{
                    color: "red",
                    fontSize: "6px",
                    marginLeft: "5px",
                    verticalAlign: "super",
                    // marginTop: "-150px",
                  }}
                  aria-hidden="true"
                ></i>
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={fixed.settlementterm}
                name="settlementterm"
                onChange={handleChange}
                label="Settlement Term"
              >
                <MenuItem value={ipCalData + opCalData}>{ipCalData == 0 && opCalData == 0 ? "Enter OP/IP Values" : numberWithCommas(ipCalData + opCalData)}</MenuItem>
                <MenuItem value={fixed.minguranteepermonth}>{fixed.minguranteepermonth ? numberWithCommas(fixed.minguranteepermonth) : "Enter MG/Month"}</MenuItem>
              </Select> */}
              <Button variant="contained" color="primary" style={{ height: "100%", width: "fit-content", marginTop: "28px" }} startIcon={<AddIcon />} onClick={addTableData}>ADD</Button>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2px", marginBottom: "20px" }}>
          {table && table.length > 0 ? (
            <Paper>
              {/* <Paper className={tables.root}> */}
              <MaterialTable
                columns={[
                  {
                    title: "Center",
                    field: "Center"
                  },
                  {
                    title: "Minimum Guarentee /Month",
                    field: "minguranteepermonth",
                    render: (row) => (<div>{row.minguranteepermonth ? "Rs. " + numberWithCommas(parseFloat(row.minguranteepermonth).toFixed()) : "---"}</div>),
                  },
                  {
                    title: "MG Period",
                    field: "mgperiod",
                    render: (row) => <div>{row.mgperiod ? row.mgperiod + " Months" : "---"}</div>
                  },
                  // {
                  //   title: "Settlement Term",
                  //   field: "settlementterm",
                  //   render: (row) => (<div>{row.settlementterm ? "Rs. " + numberWithCommas(parseFloat(row.settlementterm).toFixed()) : "---"}</div>),
                  // },
                  {
                    title: "Settlement Calculation Period",
                    field: "settlementperiod",
                  },
                  {
                    title: "Settlement Payment Period",
                    field: "settlementpaymentperiod",
                  },
                ]}
                actions={[
                  {
                    icon: "delete",
                    tooltip: "Delete",
                    iconProps: {
                      style: {
                        fontSize: "24px",
                        color: "#f55151",
                      },
                    },
                    // onClick: (event, row) => deleteRow(row),
                    onClick: (event, row) => {
                      setConfirmDialog({
                        isOpen: true,
                        title:
                          "Deleting Mg-Detail may result in some features unresponsive ?",
                        subtitle:
                          "Are you sure you want to delete this record ?",
                        onConfirm: () => {
                          deleteRow(row);
                          setConfirmDialog({ isOpen: false });
                        },
                      });
                    },
                  },
                ]}
                data={table}
                options={{
                  // filtering: true,
                  selection: true,
                  sorting: true,
                  paging: false,
                  search: false,
                  headerStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px",
                    fontSize: 12,
                  },
                  cellStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px"
                  },
                  rowStyle: {
                    fontSize: 12,
                  },
                }}
                title={<TableTitle text="Estimations" />}
              />
            </Paper>
          ) : (
            ""
          )}
        </Grid>
      </Container>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}