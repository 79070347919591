import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../images/Logos-04.png";
import { useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function AppSidebar({ userType }) {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { loading, error, user } = userLogin;
  console.log(user, "user data");

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const checkModuleAccess = (moduleName, userObj) => {
    console.log(userObj.user.roles, "roleId");
    if (userObj.user.roles === 0) {
      return true;
    }
  };
  const checkModuleResourceAccess = (
    moduleName,
    resourceName,
    actionn,
    userObj
  ) => {
    if (userObj.user.roles === 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div class="sidebar main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
      <div className="main-sidebar-header header-img">
        <Link to="/Template">
          <img src={logo2} className="img-responsive" alt="logo" />
        </Link>
      </div>
      <div className="main-sidebar-body overflow-auto">
        {checkModuleAccess("ResourceModule", user) ? (
          <Accordion
            defaultActiveKey="1"
            style={{ backgroundColor: "transparent" }}
          >
            <Card style={{ backgroundColor: "transparent" }}>
              <Card.Header style={{ backgroundColor: "transparent" }}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  style={{ color: "white" }}
                >
                  <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                  Masters
                </Accordion.Toggle>
              </Card.Header>

              {checkModuleResourceAccess(
                "ResourceModule",
                "Department",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-agreement-template"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Agreement Template
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {/* {checkModuleResourceAccess(
                "ResourceModule",
                "Department",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-firms-or-pools"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Firms
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null} */}

              {checkModuleResourceAccess(
                "ResourceModule",
                "Department",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-agreement-annexure"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Agreement Annexure
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {checkModuleResourceAccess(
                "ResourceModule",
                "Department",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-roles"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Roles
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {checkModuleResourceAccess(
                "ResourceModule",
                "Department",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-department"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Department
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {checkModuleResourceAccess(
                "ResourceModule",
                "User",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-user"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      User
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {checkModuleResourceAccess(
                "ResourceModule",
                "User",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-doctors"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Doctor
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}
              {checkModuleResourceAccess(
                "ResourceModule",
                "User",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-requisitions"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Requisitions
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}
              {checkModuleResourceAccess(
                "ResourceModule",
                "User",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/History"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Audit Trail
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {checkModuleResourceAccess(
                "ResourceModule",
                "Designation",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-designation"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Designation
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {/* {checkModuleResourceAccess(
                "ResourceModule",
                "Position",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-position"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Position
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null} */}
              {/* {checkModuleResourceAccess(
                "ResourceModule",
                "Region",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-region"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Region
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null} */}

              {checkModuleResourceAccess(
                "ResourceModule",
                "Center",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-center"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Center
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}
              {/* {checkModuleResourceAccess(
                "ResourceModule",
                "Resource",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-resource"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Resource
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null} */}

              {/* {checkModuleResourceAccess(
                "ResourceModule",
                "Permission",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-permissions"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Permission
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null} */}
            </Card>
          </Accordion>
        ) : null}
        {checkModuleAccess("Mapping", user) ? (
          <Accordion
            defaultActiveKey="1"
            style={{ backgroundColor: "transparent" }}
          >
            <Card style={{ backgroundColor: "transparent" }}>
              <Card.Header style={{ backgroundColor: "transparent" }}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  style={{ color: "white" }}
                >
                  <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                  Mapping
                </Accordion.Toggle>
              </Card.Header>

              {checkModuleResourceAccess(
                "Mapping",
                "UserMap",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/activity-assignment"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Activity Mapping
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {/* {checkModuleResourceAccess(
                "Mapping",
                "UserMap",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-firm-mapping"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Firm Mapping
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null} */}

              {/* {checkModuleResourceAccess(
                "Mapping",
                "UserMap",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-pool-mapping"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Pool Mapping
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null} */}

              {checkModuleResourceAccess(
                "Mapping",
                "UserMap",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-usermapcenter"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      User Mapping
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {checkModuleResourceAccess(
                "Mapping",
                "RegionMap",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    {/* <Link
                      to="/manage-userregionmap"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      COO Mapping
                    </Link> */}
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {/* {checkModuleResourceAccess(
                "DrmMapping",
                "RegionMap",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-drmmapping"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      DRM Mapping
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null} */}

              {/* {checkModuleResourceAccess(
                "DrmMapping",
                "RegionMap",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-UserMapping"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      User Mapping
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null} */}

            </Card>
          </Accordion>
        ) : null}

        {/* {checkModuleResourceAccess(
                "DrmMapping",
                "RegionMap",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/payout-member-map"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Payout Mapping
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}
            </Card>
          </Accordion>
        ) : null} */}

        {/* {checkModuleAccess("Reports", user) ? (
          <Accordion
            defaultActiveKey="1"
            style={{ backgroundColor: "transparent" }}
          >
            <Card style={{ backgroundColor: "transparent" }}>
              <Card.Header style={{ backgroundColor: "transparent" }}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  style={{ color: "white" }}
                >
                  <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                  Reports
                </Accordion.Toggle>
              </Card.Header>

              {checkModuleResourceAccess(
                "Reports",
                "Department",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/count-report"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Count Reports
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {checkModuleResourceAccess(
                "Reports",
                "Designation",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/BHProfile"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Designation Reports
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}
            </Card>
          </Accordion>
        ) : null} */}

        {/* {checkModuleAccess("AccessMap", user) ? (
          <Accordion
            defaultActiveKey="1"
            style={{ backgroundColor: "transparent" }}
          >
            <Card style={{ backgroundColor: "transparent" }}>
              <Card.Header style={{ backgroundColor: "transparent" }}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  style={{ color: "white" }}
                >
                  <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                  AccessMap
                </Accordion.Toggle>
              </Card.Header>

              {checkModuleResourceAccess(
                "AccessMap",
                "Permissions",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/BHProfile"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Permissions
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {checkModuleResourceAccess(
                "AccessMap",
                "AccessAllocation",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/BHProfile"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Designation Reports
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}
            </Card>
          </Accordion>
        ) : null} */}

        {/* {checkModuleAccess("Users", user) ? (
          <Accordion
            defaultActiveKey="1"
            style={{ backgroundColor: "transparent" }}
          >
            <Card style={{ backgroundColor: "transparent" }}>
              <Card.Header style={{ backgroundColor: "transparent" }}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  style={{ color: "white" }}
                >
                  <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                  User Management
                </Accordion.Toggle>
              </Card.Header>

              {checkModuleResourceAccess(
                "Users",
                "Users",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/BHProfile"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Users
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}

              {checkModuleResourceAccess(
                "Users",
                "Mapping",
                "canAccess",
                user
              ) ? (
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/BHProfile"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      Designation Reports
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}
            </Card>
          </Accordion>
        ) : null} */}
      </div>
    </div>
  );
}


export default AppSidebar;
