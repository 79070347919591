import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core'
import AddIcon from "@material-ui/icons/Add";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import { numberWithCommas, formlist } from "../../../actions/createPositionActions";
import ConfirmDialog from "../../../Components/ConfirmDialog";
import c9gif from '../../../SampleFile/cloudnine.gif';
import { APIURL } from '../../../CommomMethods/Fetch';

const stylesmodal = makeStyles({
  root: {
    marginTop: "18px",
  },
});

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function EditDoctor({ editing, setEditing, setPreview, doctorDetail, updateDoctor }) {
  const [doctor, setDoctor] = useState(doctorDetail)
  const [samePermanentAddress, setSamePermanentAddress] = useState(false);
  const [maritalStat, setmaritalStat] = useState(false);
  const [mcrnValue, setMcrnValue] = useState(false);
  const [error, setError] = useState({});
  const [filterDatas, setfilterDatas] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const dispatch = useDispatch();
  const backClasses = backDropStyle();
  const styleClasses = stylesmodal();
  const formList = useSelector((state) => state.formList);
  const { form, success } = formList;

  useEffect(() => {
    setDoctor(doctorDetail);
    if (doctorDetail.isConfirmed === 1 && doctorDetail.AdditionalEmailId) {
      setDoctor({ ...doctorDetail, ["email"]: doctorDetail.AdditionalEmailId })
    }
  }, [doctorDetail]);

  useEffect(() => {
    dispatch(formlist());
  }, [dispatch]);

  useEffect(() => {
    axios.get(APIURL() + "designations/").then((res) => {
      setfilterDatas(res.data.data);
    });
  }, []);

  
  const checkforIFSCfromindiatoday = (value) => {
    axios
    .get("https://mfapps.indiatimes.com/ET_Calculators/getBankDetailsByIfsc.htm?ifsccode=" + value)
    .then((res) => {
      console.log(res, 'ifsc code result')
      setDoctor({
        ...doctor,
        ["bankifsc"]: value,
        ["bankname"]: res.data.bankDetails.bank,
        ["branchname"]: res.data.bankDetails.branch,
      });
      setBackDropOpen(false);
      error.bankifsc = "";
      setError({ ...error });
    })
    .catch((err) => {
      setDoctor({
        ...doctor,
        ["bankifsc"]: value,
        ["bankname"]: "",
        ["branchname"]: "",
      });
      console.log(err, 'ifsc code error')
      setBackDropOpen(false);
      error.bankifsc = "Invalid ifsc code";
      setError({ ...error });
    });
  }

  const handleChangeDoctorDetail = async (event) => {
    console.log(event, 'handleChangeDoctorDetail')
    const { name, value } = event.target;
    if (value === '' || value === null) {
      setError({ ...error, [name]: "This is required field." });
    } else {
      setError({ ...error, [name]: "" });
    }
    if (name === "email") {
      var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value.length > 0) {
        if (re.test(value)) {
          error.email = "";
          setError({ ...error });
        } else {
          error.email = "Invalid email id.";
          setError({ ...error });
        }
      } else {
        error.email = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "PinCode") {
      if (value.length > 0) {
        if (value.length === 6) {
          setBackDropOpen(true);
          axios
            .get("https://api.postalpincode.in/pincode/" + value)
            .then((res) => {
              setDoctor({
                ...doctor,
                ["PinCode"]: value,
                ["State"]: res.data[0].PostOffice[0].State,
                ["City"]: res.data[0].PostOffice[0].District,
              });
              setBackDropOpen(false);
              error.PinCode = "";
              error.State = "";
              error.City = "";
              setError({ ...error });
            })
            .catch((error) => {
              axios
                .get("https://app.zipcodebase.com/api/v1/search?apikey=9e474a10-b9e5-11ee-89cf-0bfa33dc5bdc&codes=" + value)
                .then((res) => {
                  var pincodeResult = res.data.results[value][0]
                  console.log(res.data.results[value][0], 'pincodeAPI')
                  setDoctor({
                    ...doctor,
                    ["PinCode"]: value,
                    ["State"]: pincodeResult.state,
                    ["City"]: pincodeResult.city,
                  });
                  setBackDropOpen(false);
                  error.PinCode = "";
                  error.State = "";
                  error.City = "";
                  setError({ ...error });
                })
                .catch((error) => {
                  console.log(error, 'pincodeAPI')
                  error.PinCode = "Invalid Pincode again.";
                  setError({ ...error });
                  setBackDropOpen(false);
                });
            });
        } else {
          error.PinCode = "Invalid Pincode.";
          error.State = "";
          error.City = "";
          setError({ ...error });
        }
      } else {
        error.PinCode = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "indemnityvalue") {
      if (value.length > 0) {
        if (value >= 500000) {
          error.indemnityvalue = "";
          setError({ ...error });
        } else {
          error.indemnityvalue = "This is required field and it should be minimum Rs. 5,00,000/-";
          setError({ ...error });
        }
      } else {
        error.indemnityvalue = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "panno") {
      var Pan = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
      if (value.length > 0) {
        if (Pan.test(value)) {
          error.panno = "";
          setError({ ...error });
        } else {
          error.panno = "Invalid pan number.";
          setError({ ...error });
        }
      } else {
        error.panno = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "adhaarno") {
      if (value.length > 0) {
        if (value.length == 12) {
          error.adhaarno = "";
          setError({ ...error });
        } else {
          error.adhaarno = "Invalid aadhaar number.";
          setError({ ...error });
        }
      } else {
        error.adhaarno = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "bankifsc") {
      var IFSC = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      if (value.length > 0) {
        if (IFSC.test(value)) {
          error.bankifsc = "";
          if (value.length === 11) {
            setBackDropOpen(true);
            axios
              .get("https://ifsc.razorpay.com/" + value)
              .then((res) => {
                console.log(res, 'ifsc code result')
                if(res.status !== 200){
                  checkforIFSCfromindiatoday(value)
                }
                else{
                  setDoctor({
                    ...doctor,
                    ["bankifsc"]: value,
                    ["bankname"]: res.data.BANK,
                    ["branchname"]: res.data.BRANCH,
                  });
                  error.bankifsc = "";
                }
                setBackDropOpen(false);
                setError({ ...error });
              })
              .catch((err) => {
                checkforIFSCfromindiatoday(value)
                // console.log(err, 'ifsc code error')
                // setBackDropOpen(false);
                // error.bankifsc = "Invalid ifsc code";
                // setError({ ...error });
              });
          } else {
            error.bankifsc = "Invalid ifsc code.";
            setError({ ...error });
          }
        } else {
          error.bankifsc = "Invalid ifsc code.";
          setError({ ...error });
        }
      } else {
        error.bankifsc = "This is required field.";
        setError({ ...error });
      }
    }
    setDoctor({ ...doctor, [name]: value });
  }

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const checkEmail = async (e) => {
    setBackDropOpen(true);
    var email = e.target.value
    axios.post(`${APIURL()}admin/doctors/check-email`, {
      email: email,
      doctorId: doctor.doctorId
    }).then((res) => {
      setBackDropOpen(false);
      if (res.data && res.data.message) {
        error.email = res.data.message
        setError({ ...error });
      } else {
        error.email = ""
        setError({ ...error });
      }
    }).catch((err) => {
      console.log(err, 'email check error')
      setBackDropOpen(false);
    })
  };

  const mcrdateChange = async (value) => {
    if (value !== '' || value !== null) {
      error.mcrdate = "";
      setError({ ...error });
    }
    else {
      error.mcrdate = "This is required field.";
      setError({ ...error });
    }
    setDoctor({ ...doctor, mcrdate: value });
  }

  const birthdayChange = async (value) => {
    if (value !== '' || value !== null) {
      error.birthday = "";
      setError({ ...error });
    }
    else {
      error.birthday = "This is required field.";
      setError({ ...error });
    }
    setDoctor({ ...doctor, birthday: value });
  }

  const handleChangePermanentAdd = async (event) => {
    const value = event.target.checked;
    console.log(value, 'same address checkbox')
    if (value === true) {
      await setSamePermanentAddress(true);
      doctor.PermanentAddress = doctor.Address;
      doctor.PermanentState = doctor.State;
      doctor.PermanentCity = doctor.City;
      doctor.PermanentPinCode = doctor.PinCode;
      error.PermanentAddress = "";
      error.PermanentState = "";
      error.PermanentCity = "";
      error.PermanentPinCode = "";
      await setError({ ...error })
    } else {
      await setSamePermanentAddress(false);
      doctor.PermanentAddress = "";
      doctor.PermanentState = "";
      doctor.PermanentCity = "";
      doctor.PermanentPinCode = "";
      error.PermanentAddress = "This is required field.";
      error.PermanentState = "This is required field.";
      error.PermanentCity = "This is required field.";
      error.PermanentPinCode = "This is required field.";
      await setError({ ...error })
    }
    await setDoctor({ ...doctor, doctor });
  }

  const handleChangemaritalStat = (e) => {
    setmaritalStat(e.target.checked);
  };

  const handleChangeMCRN = (e) => {
    setMcrnValue(e.target.checked);
  };

  const handleOnboardingDetails = async () => {
    error.fullname = doctor.fullname != "" && doctor.fullname != null ? "" : "This is required field.";
    error.fathername = doctor.fathername != "" && doctor.fathername != null ? "" : "This is required field.";
    error.mobile = doctor.mobile != "" && doctor.mobile != null ? "" : "This is required field.";
    error.email = doctor.email != "" && doctor.email != null ? "" : "This is required field.";
    error.Address = doctor.Address != "" && doctor.Address != null ? "" : "This is required field.";
    error.State = doctor.State != "" && doctor.State != null ? "" : "This is required field.";
    error.City = doctor.City != "" && doctor.City != null ? "" : "This is required field.";
    error.PinCode = doctor.PinCode != "" && doctor.PinCode != null ? "" : "This is required field.";
    error.PermanentAddress =
      doctor.PermanentAddress != "" && doctor.PermanentAddress != null ? "" : "This is required field.";
    error.PermanentState = doctor.PermanentState != "" && doctor.PermanentState != null ? "" : "This is required field.";
    error.PermanentCity = doctor.PermanentCity != "" && doctor.PermanentCity != null ? "" : "This is required field.";
    error.PermanentPinCode = doctor.PermanentPinCode != "" && doctor.PermanentPinCode != null ? "" : "This is required field.";
    error.aboutdoctor = doctor.aboutdoctor != "" && doctor.aboutdoctor != null ? "" : "This is required field.";
    if (maritalStat) {
      error.husbandname = doctor.husbandname != "" && doctor.husbandname != null ? "" : "This is required field.";
    }
    error.bankaccno = doctor.bankaccno != "" && doctor.bankaccno != null ? "" : "This is required field.";
    error.bankname = doctor.bankname != "" && doctor.bankname != null ? "" : "This is required field.";
    error.branchname = doctor.branchname != "" && doctor.branchname != null ? "" : "This is required field.";
    error.designationlist = doctor.designationlist != "" && doctor.designationlist != null ? "" : "This is required field.";
    error.departmentlist = doctor.departmentlist != "" && doctor.departmentlist != null ? "" : "This is required field.";
    error.noticeperiod = doctor.noticeperiod != "" && doctor.noticeperiod != null ? "" : "This is required field.";
    error.lockinperiod = doctor.lockinperiod != "" && doctor.lockinperiod != null ? "" : "This is required field.";
    error.panno = doctor.panno != "" && doctor.panno != null ? "" : "This is required field.";
    error.adhaarno = doctor.adhaarno != "" && doctor.adhaarno != null ? "" : "This is required field.";
    var Pan = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if (doctor.panno && doctor.panno.length > 0) {
      if (Pan.test(doctor.panno)) {
        error.panno = "";
      } else {
        error.panno = "Invalid pan number.";
      }
    } else {
      error.panno = "This is required field.";
    }
    if (doctor.adhaarno && doctor.adhaarno.length > 0) {
      if (doctor.adhaarno.length == 12) {
        error.adhaarno = "";
      } else {
        error.adhaarno = "Invalid aadhaar number.";
      }
    } else {
      error.adhaarno = "This is required field.";
    }
    error.bankifsc = doctor.bankifsc != "" && doctor.bankifsc != null ? "" : "This is required field.";
    error.indemnityvalue = doctor.indemnityvalue != "" && doctor.indemnityvalue != null && doctor.indemnityvalue >= 500000 ? "" : "This is required field and it should be minimum Rs. 5,00,000/-";
    error.pgdegree = doctor.pgdegree != "" && doctor.pgdegree != null ? "" : "This is required field.";
    error.pgtotalexp = doctor.pgtotalexp != "" && doctor.pgtotalexp != null ? "" : "This is required field.";
    error.pgrelevantexp = doctor.pgrelevantexp != "" && doctor.pgrelevantexp != null ? "" : "This is required field.";
    error.medicaldegree = doctor.medicaldegree != "" && doctor.medicaldegree != null ? "" : "This is required field.";
    error.mcrstate = doctor.mcrstate != "" && doctor.mcrstate != null ? "" : "This is required field.";
    error.birthday = doctor.birthday != "" && doctor.birthday != null ? "" : "This is required field.";
    error.Gender = doctor.Gender != "" && doctor.Gender != null ? "" : "This is required field.";
    if (mcrnValue == false) {
      error.mcrcval = doctor.mcrcval != "" && doctor.mcrcval != null ? "" : "This is required field.";
      error.mcrdate = doctor.mcrdate != "" && doctor.mcrdate != null ? "" : "This is required field.";
      // error.mcrc = mcrc != "" && mcrc != null ? "" : "This is required field.";
    }
    else {
      error.mcrdate = doctor.mcrdate != "" && doctor.mcrdate != null ? "" : "This is required field.";
      // error.sublaterdays = sublaterdays != "" && sublaterdays != null ? "" : "This is required field.";
      error.mcrsubreason = doctor.mcrsubreason != "" && doctor.mcrsubreason != null ? "" : "This is required field.";
    }
    await setError({ ...error });

    console.log(error, "errors for profile");

    if (Object.keys(error).length) {
      setBackDropOpen(true)
      const isEmpty = Object.values(error).every((x) => x === "");
      console.log(isEmpty, "rcc");
      if (isEmpty === false) {
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Please fill required fields",
        });
        setBackDropOpen(false);
        return;
      } else {
        updateDoctor(doctor.doctorId, doctor)
      }
    }
  }

  const name = <p>Name in Full<span style={{ color: "red" }} > *</span></p>;
  const name0 = <p>Father's Name<span style={{ color: "red" }} > *</span></p>;
  const name1 = <p>Enter Mobile Number<span style={{ color: "red" }} > *</span></p>;
  const name2 = <p>Current Address<span style={{ color: "red" }} > *</span></p>;
  const name3 = <p>City<span style={{ color: "red" }} > *</span></p>;
  const name4 = <p>Permanent Address<span style={{ color: "red" }} > *</span></p>;
  const name5 = <p>About Doctor<span style={{ color: "red" }} > *</span></p>;
  const name6 = <p>Spouse Name<span style={{ color: "red" }} > *</span></p>;
  const name7 = <p>Email Address<span style={{ color: "red" }} > *</span></p>;
  const name8 = <p>State<span style={{ color: "red" }} > *</span></p>;
  const name9 = <p>Pin Code<span style={{ color: "red" }} > *</span></p>;
  const name10 = <p>Date of Birth<span style={{ color: "red" }} > *</span></p>;
  const name11 = <p>Designation<span style={{ color: "red" }} > *</span></p>;
  const name12 = <p>Business Unit(s)<span style={{ color: "red" }} > *</span></p>;
  const name13 = <p>Post PG Degree Total Experience<span style={{ color: "red" }} > *</span></p>;
  const name14 = <p>Post PG Degree Relevant Experience<span style={{ color: "red" }} > *</span></p>;
  const name15 = <p>Medical Degree<span style={{ color: "red" }} > *</span></p>;
  const name16 = <p>Lock in period<span style={{ color: "red" }} > *</span></p>;
  const name17 = <p>Additional Qualification<span style={{ color: "red" }} > </span></p>;
  const name18 = <p>Department<span style={{ color: "red" }} > *</span></p>;
  const name19 = <p>Specific Period of Contract<span style={{ color: "red" }} > *</span></p>;
  const name20 = <p>Department<span style={{ color: "red" }} > *</span></p>;
  const name21 = <p>Existing Indemnity Insurance Value<span style={{ color: "red" }} > *</span></p>;
  const name22 = <p>Highest PG Degree<span style={{ color: "red" }} > *</span></p>;
  const name23 = <p>Notice period<span style={{ color: "red" }} > *</span></p>;
  const name24 = <p>PAN<span style={{ color: "red" }} > *</span></p>;
  const name25 = <p>IFSC Code<span style={{ color: "red" }} > *</span></p>;
  const name26 = <p>Branch Name<span style={{ color: "red" }} > *</span></p>;
  const name27 = <p>Aadhaar Card Number<span style={{ color: "red" }} > *</span></p>;
  const name28 = <p>Bank Name<span style={{ color: "red" }} > *</span></p>;
  const name29 = <p>Bank Account Number<span style={{ color: "red" }} > *</span></p>;
  return (
    <>
      <Paper>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleOnboardingDetails();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <TextField
                id="outlined-basic"
                label={name}
                // label="Name in Full"
                // InputLabelProps={{
                //   classes: {
                //     root: classes.cssLabel
                //   },
                //   FormLabelClasses: {
                //     asterisk: classes.labelAsterisk
                //   }
                // }}
                variant="outlined"
                fullWidth
                // required
                name="fullname"
                value={doctor.fullname}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon /> Dr.
                    </InputAdornment>
                  ),
                }}
                // required

                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.fullname}
              />
              <TextField
                id="outlined-basic"
                label={name1}
                variant="outlined"
                fullWidth
                // required
                name="mobile"
                value={doctor.mobile}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.mobile}
              />
              <TextField
                id="outlined-basic"
                label={name2}
                // label="Current Address"
                variant="outlined"
                fullWidth
                // required
                name="Address"
                value={doctor.Address}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <AddressIcon /> */}
                    </InputAdornment>
                  ),
                }}
                multiline
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.Address}
              />
              <TextField
                disabled
                id="outlined-basic"
                label={name3}
                // label="City"
                // required
                variant="outlined"
                fullWidth
                name="City"
                value={doctor.City}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <EmailIcon /> */}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.City}
              />
              <div>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{
                      color: "black",
                      fontSize: "13px",
                      marginBottom: "20px",
                    }}
                  ></FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={
                        <Checkbox
                          color="primary"
                          checked={samePermanentAddress}
                          onChange={(e) => handleChangePermanentAdd(e)}
                        />
                      }
                      label="Same as Current Address?"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>{" "}
              </div>
              <TextField
                disabled={samePermanentAddress === true}
                id="outlined-basic"
                // label="Permanent Address"
                label={name4}
                variant="outlined"
                fullWidth
                // required
                name="PermanentAddress"
                value={doctor.PermanentAddress}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px", marginTop: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <AddressIcon /> */}
                    </InputAdornment>
                  ),
                }}
                multiline
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.PermanentAddress}
              />
              <TextField
                id="outlined-basic"
                label="City"
                variant="outlined"
                fullWidth
                name="PermanentCity"
                value={doctor.PermanentCity}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <EmailIcon /> */}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.PermanentCity}
              />
              <TextField
                id="outlined-basic"
                // label="About Doctor"
                label={name5}
                variant="outlined"
                fullWidth

                name="aboutdoctor"
                value={doctor.aboutdoctor}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <AddressIcon /> */}
                    </InputAdornment>
                  ),
                }}
                multiline
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.aboutdoctor}
              />
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{ color: "black", fontSize: "13px" }}
                ></FormLabel>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        color="primary"
                        checked={maritalStat}
                        onChange={handleChangemaritalStat}
                      />
                    }
                    label="Marital Status(Yes)?"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>{" "}
              {maritalStat ? (
                <div>
                  <div className="form-group">
                    <TextField
                      name="husbandname"
                      value={doctor.husbandname}
                      // label={"Spouse Name"}
                      label={name6}
                      onChange={(e) => handleChangeDoctorDetail(e)}
                      className={styleClasses.root}
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span>
                              {doctor.Gender == "MALE"
                                ? "Mrs. "
                                : doctor.Gender == "FEMALE"
                                  ? "Mr. "
                                  : "Mr / Mrs"}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      helperText={error.husbandname}
                      fullWidth
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={6} sm={6} xl={6}>
              <TextField
                id="outlined-basic"
                label={name7}
                // label="Email Address"
                variant="outlined"

                fullWidth
                name="email"
                value={doctor.email}
                onChange={(e) => handleChangeDoctorDetail(e)}
                onBlur={(e) => checkEmail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.email}
              />
              <TextField
                id="outlined-basic"
                label={name0}
                variant="outlined"
                fullWidth

                name="fathername"
                value={doctor.fathername}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.fathername}
              />

              <TextField
                disabled
                id="outlined-basic"
                label={name8}
                // label="State"
                variant="outlined"
                fullWidth

                name="State"
                value={doctor.State}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <EmailIcon /> */}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.State}
              />
              <TextField
                id="outlined-basic"
                label="Pin Code"
                // label="Pin Code"
                variant="outlined"
                fullWidth

                name="PinCode"
                inputProps={{ maxLength: 6 }}
                onInput={(e) => onlyNumbers(e)}
                value={doctor.PinCode}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <EmailIcon /> */}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.PinCode}
              />
              <div className="form-group">
                <FormControl component="fieldset" className="form-group">
                  <FormLabel component="legend">Gender <i
                    class="fa fa-asterisk"
                    style={{
                      color: "red",
                      fontSize: "7px",
                      marginLeft: "6px",
                      marginBottom: "-10px",
                      verticalAlign: "super"

                    }}
                    aria-hidden="true"
                  ></i></FormLabel>
                  <RadioGroup
                    className="w-100"
                    row
                    aria-label="Gender"
                    name="Gender"
                    value={doctor.Gender}
                    onChange={(e) => handleChangeDoctorDetail(e)}
                  >
                    <FormControlLabel
                      value="MALE"
                      control={<Radio color="#fd7e14" />}
                      label="Male"
                      checked={doctor.Gender == "MALE" ? true : false}
                    />
                    <FormControlLabel
                      value="FEMALE"
                      control={<Radio color="#00963f" />}
                      label="Female"
                      checked={doctor.Gender == "FEMALE" ? true : false}
                    />
                    <FormControlLabel
                      value="OTHER"
                      control={<Radio color="#00963f" />}
                      label="Other"
                      checked={doctor.Gender == "OTHER" ? true : false}
                    />
                  </RadioGroup>
                  <div style={{ color: "red", fontSize: "12px" }}>{error.Gender}</div>
                </FormControl>
              </div>

              <TextField
                id="outlined-basic"
                // label="State"
                label={name8}
                variant="outlined"
                fullWidth

                name="PermanentState"
                value={doctor.PermanentState}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <EmailIcon /> */}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.PermanentState}
              />
              <TextField
                disabled={samePermanentAddress === true}
                id="outlined-basic"
                // label="Pin Code"
                label={name9}
                variant="outlined"
                fullWidth

                name="PermanentPinCode"
                inputProps={{ maxLength: 6 }}
                onInput={(e) => onlyNumbers(e)}
                value={doctor.PermanentPinCode}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <EmailIcon /> */}
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.PermanentPinCode}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  maxDate={(new Date()).setFullYear((new Date()).getFullYear() - 18)}
                  // disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  autoOk
                  label={name10}
                  // label="Date of Birth"
                  // disablePast

                  id="birthday"
                  value={doctor.birthday}
                  className={styleClasses.root}
                  onChange={(e) => birthdayChange(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
              <div style={{ color: "red", fontSize: "12px" }}>{error.birthday}</div>
            </Grid>
          </Grid>
          <hr />
          <h2
            style={{
              backgroundColor: "#810e36",
              color: "white",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {" "}
            Qualification & Experience
          </h2>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <label for="designationlist">
                <h6>
                  {name11}
                </h6>
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="designationlist"
                label={name11}
                variant="outlined"
                fullWidth

                name="designationlist"
                value={doctor.designationlist}
                onChange={(e) => handleChangeDoctorDetail(e)}
              // required
              >
                <option disabled>Select Designation</option>
                {filterDatas &&
                  filterDatas.map((ele) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
              <div style={{ color: "red", fontSize: "12px" }}>{error.designationlist}</div>

              <br />

              <TextField
                id="outlined-basic"
                // label="Business Unit(s)"
                label={name12}
                variant="outlined"
                fullWidth

                name="businessunit"
                value={doctor.businessunit}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                disabled
              />
              <br />
              <TextField
                id="outlined-basic"
                label={name13}
                // label="Post PG Degree Total Experience"
                variant="outlined"
                fullWidth

                name="pgtotalexp"
                value={doctor.pgtotalexp}
                onChange={(e) => handleChangeDoctorDetail(e)}
                onInput={(e) => onlyNumbers(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>In Months</span>
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.pgtotalexp}
              />
              <TextField
                id="outlined-basic"
                label={name14}
                // label="Post PG Degree Relevant Experience"
                variant="outlined"
                fullWidth

                name="pgrelevantexp"
                value={doctor.pgrelevantexp}
                onInput={(e) => onlyNumbers(e)}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>In Months</span>
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.pgrelevantexp}
              />
              <TextField
                id="outlined-basic"
                label={name15}
                variant="outlined"
                fullWidth

                name="medicaldegree"
                value={doctor.medicaldegree}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.medicaldegree}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <label for="departmentlist">
                <h6>
                  {name18}
                </h6>
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="departmentlist"
                name="departmentlist"
                value={doctor.departmentlist}
                onChange={(e) => handleChangeDoctorDetail(e)}
              // required
              >
                <option disabled>Department</option>
                {form.departmentList &&
                  form.departmentList.map((ele) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
              <div style={{ color: "red", fontSize: "12px" }}>{error.departmentlist}</div>
              <br />
              <TextField
                id="outlined-basic"
                label={name21}
                variant="outlined"
                fullWidth

                name="indemnityvalue"
                value={
                  doctor.indemnityvalue ? numberWithCommas(doctor.indemnityvalue) : ""
                }
                // InputProps={{ inputProps: { min: 500000 } }}
                // value={details.indemnityvalue ? numberWithCommas(details.indemnityvalue) : ""}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                onInput={(e) => onlyNumbers(e)}
                autoComplete="off"
                InputProps={{
                  inputProps: { min: 500000 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>Rs.</span>
                    </InputAdornment>
                  ),
                  inputProps: { min: 500000 },
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.indemnityvalue}
              />

              <TextField
                id="outlined-basic"
                // label="Highest PG Degree"
                variant="outlined"
                fullWidth
                label={name22}
                name="pgdegree"
                value={doctor.pgdegree}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.pgdegree}
              />

              <TextField
                id="outlined-basic"
                label={name23}
                variant="outlined"
                fullWidth
                name="noticeperiod"
                onInput={(e) => onlyNumbers(e)}
                value={doctor.noticeperiod}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>In Months</span>
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.noticeperiod}
              />
              <TextField
                id="outlined-basic"
                label={name16}
                // label="Lock in period"
                variant="outlined"
                fullWidth
                name="lockinperiod"
                value={doctor.lockinperiod}
                onInput={(e) => onlyNumbers(e)}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>In Months</span>
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.lockinperiod}
              />
            </Grid>
          </Grid>
          <br />
          <hr />
          <h2
            style={{
              backgroundColor: "#810e36",
              color: "white",
              padding: "5px",
              textAlign: "center",
            }}
          >
            Other Information
          </h2>
          <hr />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <TextField
                id="outlined-basic"
                label={name24}
                variant="outlined"
                fullWidth
                name="panno"

                value={doctor.panno}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                inputProps={{ maxLength: 10 }}
                // disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.panno}
              />
              <TextField
                id="outlined-basic"
                label={name25}
                variant="outlined"
                fullWidth
                name="bankifsc"
                // required
                value={doctor.bankifsc}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.bankifsc}
              />
              <TextField
                id="outlined-basic"
                label={name26}
                variant="outlined"
                fullWidth
                // required
                name="branchname"
                value={doctor.branchname}
                disabled
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.branchname}
              />
              <div>
                <label for="pg-degree">
                  <h6>
                    {" "}
                    State of Registration{" "}
                    <i
                      class="fa fa-asterisk"
                      style={{
                        color: "red",
                        fontSize: "7px",
                        marginLeft: "6px",
                        verticalAlign: "super",
                      }}
                      aria-hidden="true"
                    ></i>
                  </h6>
                </label>
                <select
                  name="mcrstate"
                  id="state"
                  className="form-control"
                  style={{ color: "#000000" }}
                  onChange={(e) => handleChangeDoctorDetail(e)}
                  value={doctor.mcrstate}
                >
                  <option value="" disabled selected hidden>
                    Select the state of registration
                  </option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Andaman and Nicobar Islands">
                    Andaman and Nicobar Islands
                  </option>
                  <option value="Arunachal Pradesh">
                    Arunachal Pradesh
                  </option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Dadar and Nagar Haveli">
                    Dadar and Nagar Haveli
                  </option>
                  <option value="Daman and Diu">Daman and Diu</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Lakshadweep">Lakshadweep</option>
                  <option value="Puducherry">Puducherry</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">
                    Himachal Pradesh
                  </option>
                  <option value="Jammu and Kashmir">
                    Jammu and Kashmir
                  </option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
                <div style={{ color: "red", fontSize: "12px" }}>{error.mcrstate}</div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <TextField
                id="outlined-basic"
                label={name27}
                variant="outlined"
                fullWidth
                name="adhaarno"
                // required
                value={doctor.adhaarno}
                inputProps={{ maxLength: 12 }}
                onInput={(e) => onlyNumbers(e)}
                onChange={(e) => handleChangeDoctorDetail(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.adhaarno}
              />
              <TextField
                id="outlined-basic"
                label={name28}
                variant="outlined"
                fullWidth
                name="bankname"
                // required
                value={doctor.bankname}
                onChange={(e) => handleChangeDoctorDetail(e)}
                disabled
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.bankname}
              />
              <TextField
                id="outlined-basic"
                label={name29}
                variant="outlined"
                fullWidth
                // required
                name="bankaccno"
                value={doctor.bankaccno}
                onChange={(e) => handleChangeDoctorDetail(e)}
                onInput={(e) => onlyNumbers(e)}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.bankaccno}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12} xl={12}>
              <Row>
                {/* <Col md={6}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      style={{ color: "black", fontSize: "13px" }}
                    ></FormLabel>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={
                          <Checkbox
                            color="primary"
                            checked={mcrnValue}
                            onChange={handleChangeMCRN}
                          />
                        }
                        label="Submit Medical Council Registration Certificate No Later?"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>{" "}
                </Col> */}
                <Col md={6}>
                  <div>
                    {!mcrnValue ? (
                      <div>
                        <label for="pg-degree">
                          <h6 id="MCRC">
                            {" "}
                            Medical Council Registration Certificate No <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "7px",
                                marginLeft: "5px",
                                verticalAlign: "super",
                              }}
                              aria-hidden="true"
                            ></i>
                          </h6>
                        </label>
                        <div
                          className="form-group"
                          style={{
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <label style={{
                            fontSize: "smaller",
                            marginTop: "-9px",

                          }}
                          >
                            Certificate No
                            <input
                              type="text"
                              value={doctor.mcrcval}
                              name={"mcrcval"}
                              id="medicalcertno"
                              onChange={(e) => handleChangeDoctorDetail(e)}
                              style={{ color: "#000000", width: "120px" }}
                              className="form-control"
                              placeholder="Medical Council Registration Certificate No"
                            />
                            <div style={{ color: "red", fontSize: "12px", width: "120px" }}>{error.mcrcval}</div>
                          </label>
                          <label
                            style={{
                              fontSize: "smaller",
                              marginTop: "-9px",
                            }}
                          >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="normal"
                                // disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                // minDate={new Date()}
                                autoOk
                                label={'Expiry Date'}
                                // label="Date of Birth"
                                // disablePast
                                name='mcrdate'
                                id="certIssueDate"
                                value={doctor.mcrdate}
                                className={styleClasses.root}
                                onChange={(e) => mcrdateChange(e)}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                fullWidth
                              />
                            </MuiPickersUtilsProvider>
                            <div style={{ color: "red", fontSize: "12px" }}>{error.mcrdate}</div>
                          </label>
                          <label
                            style={{
                              fontSize: "smaller",
                              marginTop: "-9px",
                            }}
                          >
                            Certificate:
                            <input
                              type="file"
                              id={2}
                              name="mcrc"
                              // onChange={fileHandler1}
                              className="form-control"
                            />
                            <div style={{ color: "red", fontSize: "12px" }}>{error.mcrc}</div>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <label for="pg-degree">
                          <h6>
                            {" "}
                            Reason and Date for Later Submission{" "} <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "7px",
                                marginLeft: "6px",
                                verticalAlign: "super",
                              }}
                              aria-hidden="true"
                            ></i>
                          </h6>
                        </label>
                        <div className="form-group">
                          <label
                            style={{
                              fontSize: "smaller",
                              marginTop: "-9px",
                            }}>
                            Reason:
                            <div style={{ color: "red", fontSize: "12px" }}>{error.mcrsubreason}</div>
                            <textarea
                              value={doctor.mcrsubreason}
                              id="mcrsubreason"
                              onChange={(e) => handleChangeDoctorDetail(e)}
                              style={{ color: "#000000", maxHeigth: '38px' }}
                              className="form-control"
                              placeholder="Reason for Later Submission"
                            />
                          </label>
                          <label
                            style={{
                              fontSize: "smaller",
                              marginTop: "-9px",
                            }}>
                            Duration:
                            <div style={{ color: "red", fontSize: "12px" }}>{error.sublaterdays}</div>
                            <select
                              className="form-control"
                              style={{ color: "black", margin: ".2rem" }}
                              id="sublaterdays"
                              name="sublaterdays"
                            // onChange={selectedSub}
                            >
                              <option value="0" disabled selected hidden>Late Duration</option>
                              <option value="30 days">30 days</option>
                              <option value="45 days">45 days</option>
                              <option value="60 days">60 days</option>
                              <option value="90 days">90 days</option>
                            </select>
                          </label>
                          <label
                            style={{
                              fontSize: "smaller",
                              marginTop: "-9px",
                            }}>
                            Date:
                            <div style={{ color: "red", fontSize: "12px" }}>{error.mcrdate}</div>
                            <input
                              type="date"
                              name='mcrdate'
                              value={doctor.mcrdate}
                              id="mcrdate"
                              // onChange={(e) =>
                              //   setCertIssueDate(e.target.value)
                              // }
                              onChange={(e) => handleChangeDoctorDetail(e)}
                              style={{ color: "#000000", margin: "5px" }}
                              className="form-control"
                            /></label>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </Grid>
          <hr />
          <br />

          <Button
            variant="contained"
            style={{
              float: "Right",
              margin: "2rem",
              backgroundColor: "#810e36",
            }}
            type="submit"
          >
            Submit
          </Button>
        </form>
        <Backdrop
          className={backClasses.backdrop}
          open={backDropOpen}
        // onClick={handleClose}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img src={c9gif} alt="loading..." />
        </Backdrop>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </Paper>
    </>
  )
}

export default EditDoctor