import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { useTheme } from "@mui/material/styles";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import ui components
import Fixed from "../Pages/BusinessHead/Onboard/Fixed";
import Visiting from "../Pages/BusinessHead/Onboard/Visiting";
import Mg from "../Pages/BusinessHead/Onboard/Mg";
import OpDetail from "../Pages/BusinessHead/Onboard/OpDetail";
import IpDetail from "../Pages/BusinessHead/Onboard/IpDetails";

const Accordion = withStyles({
  root: {
    border: "1px solid #e35473",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#20c9970d",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "20px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    fontWeight: "bold",
    color: "#ba2948",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function TabComponent({
  centerData,
  centerList,
  data,
  doctorData,
  onchangeOpTable,
  category,
  onchangeOpTableCommercial,
  onchangeIpTable,
  onchangeIpTableCommercial,
  onchnagecategory,
  categorychk,
  onchnagecategoryTable,
  primaryCenter,
}) {
  // const theme = useTheme();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [opCalData, setOpCalData] = useState("");
  const [ipCalData, setIpCalData] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sentCategory = (data) => {
    onchnagecategory(data);
  };
  const sentDataToParent = (data) => {
    onchnagecategoryTable(data);
  };

  const sendDataToParent = (index, indexdata) => {
    onchangeOpTable(index);
    onchangeOpTableCommercial(indexdata);
  };

  const sendDataTIpParent = (index, indexdata) => {
    onchangeIpTable(index);
    onchangeIpTableCommercial(indexdata);
  };

  const sendCalculatedData = (index) => {
    setOpCalData(index);
  };

  const sendCalculatedIpData = (index) => {
    setIpCalData(index);
  };
  return (
    <>
      <Typography variant="h6" style={{ textAlign: "center" }}>
        {" "}
        Estimated Commercial
      </Typography>
      <div className={classes.root}>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>OP Details</Typography>
          </AccordionSummary>
          <OpDetail
            sendDataToParent={sendDataToParent}
            sendCalculatedData={sendCalculatedData}
            primaryCenter={primaryCenter}
            data={data}
            doctorData={doctorData}
            centerData={centerData}
            centerList={centerList}
          />
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading}>IP Details</Typography>
          </AccordionSummary>
          <IpDetail
            sendDataTIpParent={sendDataTIpParent}
            sendCalculatedIpData={sendCalculatedIpData}
            primaryCenter={primaryCenter}
            propdata={data}
            doctorData={doctorData}
            centerData={centerData}
            centerList={centerList}
          />
        </Accordion>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {" "}
            {categorychk === "Minimum Guarentee(MG)" ? (
              <Typography style={{ fontWeight: "bold", color: "#ba2948" }}>
                {" "}
                Fixed MG
              </Typography>
            ) : (
              <Typography style={{ fontWeight: "bold", color: "#ba2948" }}>
                {" "}
                Fixed Fee
              </Typography>
            )}
          </AccordionSummary>
          {categorychk === "Minimum Guarentee(MG)" ? (
            <Fixed
              sentCategory={sentCategory}
              sentDataToParent={sentDataToParent}
              opCalData={opCalData}
              ipCalData={ipCalData}
              category={category}
              doctorData={doctorData}
              data={data}
              primaryCenter={primaryCenter}
              centerData={centerData}
              centerList={centerList}
            />
          ) : (
            <Visiting sentCategory={sentCategory} sentDataToParent={sentDataToParent} doctorData={doctorData} data={data} category={category} centerData={centerData} centerList={centerList} primaryCenter={primaryCenter} />
          )}
        </Accordion>
      </div>
    </>
  );
}
