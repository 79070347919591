import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import MaterialTable from "material-table";
import Container from "@mui/material/Container";
import Navbar from "../../Components/Navbar";
import AddIcon from "@material-ui/icons/Add";
import { login } from "../../actions/authActions";
import AppSidebar from "../../Components/AppSidebar";
import Sidebar from "../../Components/Sidebar";
import DoctorSidebar from "../../Components/DrmAppSidebar";
import axios from "axios";
import Button from "@mui/material/Button";
import { Modal, Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import Radio from "@material-ui/core/Radio";
import { postRecord, putRecord } from "../../CommomMethods/Save";
import { fetchAllRecords, fetchRecordByID } from "../../CommomMethods/Fetch";
import { APIURL } from "../../CommomMethods/Fetch";
import RadioGroup from "@material-ui/core/RadioGroup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@mui/material/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { listusers } from "../../actions/userActions";
import { centerlist } from "../../actions/centerActions";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../Components/ConfirmDialog";
import EditProfiledata from "../Doctor/EditProfileDetail";
import { numberWithCommas } from "../../actions/createPositionActions";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { agreementState } from "../../CommomMethods/States";
import c9gif from '../../SampleFile/cloudnine.gif';
const stylesmodal = makeStyles({
  root: {
    marginTop: "18px",
  },
});
const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function ViewProfile() {
  const [backDropOpen, setBackDropOpen] = useState(false);
  const backClasses = backDropStyle();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const location = useLocation();
  let history = useHistory();
  const styleClasses = stylesmodal();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  //   console.log(user.user, "doctor-detail");
  console.log(location.state.state, "location-pass-data");

  const initialForm = {
    // centers: "",
    fullname: location.state.state.name,
    fathername: "",
    husbandname: "",
    Address: "",
    Gender: "",
    State: "",
    City: "",
    PinCode: "",
    PermanentAddress: "",
    PermanentState: "",
    PermanentCity: "",
    aboutdoctor: "",
    PermanentPinCode: "",
    noticeperiod: "",
    lockinperiod: "",
    indemnityvalue: "",
    indemnityexpiry: "",
    bankname: "",
    bankifsc: "",
    bankaccno: "",
    panno: "",
    birthday: new Date().setFullYear(new Date().getFullYear() - 18),
    mobile: location.state.state.mobile,
    email: location.state.state.email,
    adhaarno: "",
    designationlist: "",
    departmentlist: "",
    businessunit: "",
    contractlength: location.state.state.contractterm,
    // doj: "",
    mcrdate: "",
    mcrstate: "",
    mcrcval: "",
    mcrsubreason: "",
    // stampdate: "",
    // confirmdoj: "",
    // agreementexpirydate: "",
    // ctcmonth: "",
    pgdegree: "",
    pgtotalexp: "",
    pgrelevantexp: "",
    doctorId: location.state.state.id,
    status: 1,
  };

  const [add, setAdd] = useState({
    doctorId: location.state.state.id,
    DoctorName: location.state.state.username,
    qualification: "",
    status: 1,
  });
  const [isError, setIsError] = useState(false);
  const [details, setDetails] = useState(initialForm);
  const [chechBox, setCheckBox] = useState("false");
  const [date, setDate] = React.useState(new Date());
  const [mcrnValue, setMcrnValue] = useState(false);
  const [mcrdate, setMcrDate] = React.useState(new Date());
  const [birthdaydate, setBirthdayDate] = React.useState(null);
  const [joiningdate, setJoiningDate] = React.useState(new Date());
  const [mcrcValue, setMcrcValue] = useState();
  const [certIssueDate, setCertIssueDate] = useState();
  const [filename, setFilename] = useState("");
  const [adhaar, setAdhaar] = useState("");
  const [filePath, setfilePath] = useState();
  const [mcrc, setmcrc] = useState();
  const [addQual, setAddQual] = useState();
  const [indemnityExpirydate, setIndemnityExpirydate] = React.useState(
    new Date()
  );
  // const [stampdate, setStampdate] = React.useState(new Date());
  const [maritalStat, setmaritalStat] = useState(false);
  const [data, setData] = useState({});
  const [indemnityvalue, setindemnityvalue] = useState(5000000);
  const [stateval, setstateval] = useState("");
  const [table, setTable] = useState([]);
  const [sublaterdays, setsublaterdays] = useState("");
  const [indemnityexpiry, setindemnityexpiry] = useState("");
  const [onboardData, setOnboardData] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [mcrcReasonValue, setMcrcReasonValue] = useState("");
  const [mcrcReasonValueDate, setmcrcReasonValueDate] = useState();
  const [fname, setFname] = useState("");
  const [pAdd, setPAdd] = useState("");
  const [about, setAbout] = useState("");
  const [conlen, setConLen] = useState("");
  const [pgdeg, setpgDeg] = useState("");
  const [med, setMed] = useState("");
  const [totpgexp, setTotPgExp] = useState("");
  const [relExp, setRelExp] = useState("");
  const [addpin, setAddPinDet] = useState("");
  const [addCity, setAddCity] = useState("");
  const [addState, setAddState] = useState("");

  // const [chkitem, setChkitem] = useState([location.state.data.centerNames]);
  // const [chkitemId, setChkitemId] = useState([location.state.data.id]);

  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const centerList = useSelector((state) => state.centerList);
  const { centers } = centerList;

  const [noticeperiod, setNoticeperiod] = useState([]);
  const [lockinperiod, setLockinperiod] = useState([]);
  function selectedDropdownvalue(e) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }
  var newdata = [];
  function selectedState(e) {
    newdata = { ...stateval };
    newdata[e.target.id] = e.target.value;
    console.log(newdata.state, "gfgfgfgfccg")
    if (newdata.state == "" || newdata.state == null) {
      error.mcrstate = "This is required field.";
      setError({ ...error });
    }
    else {
      error.mcrstate = "";
      setError({ ...error });
    }
    setDetails({ ...details, ["mcrstate"]: newdata.state });
    setstateval(newdata);
    console.log(newdata, e, 'newdata')
  }
  var newdatasub = [];
  function selectedSub(e) {
    newdatasub = { ...sublaterdays };
    newdatasub[e.target.id] = e.target.value;
    if (e.target.value != null || e.target.value != "0") {
      error.sublaterdays = "";
      setError({ ...error });
    }
    else {
      error.sublaterdays = "This is required field.";
      setError({ ...error });
    }
    setsublaterdays(newdatasub);
  }

  // var bcenter = location.state.data.centerNames;
  // var centerArray = [];

  // {
  //   users &&
  //     users[0] &&
  //     users[0].centers.map((ele) => {
  //       centerArray.push(ele);
  //     });
  // }
  // var k = centerArray.filter((z) => z.name === bcenter);
  // var l = centerArray.filter((z) => z.region_id === k[0].region_id);
  const [docGender, setDocGender] = useState("");
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    var genderVal = "";
    setValue(event.target.value);
    if (event.target.value === "M") {
      genderVal = "MALE";
    } else if (event.target.value === "F") {
      genderVal = "FEMALE";
    } else if (event.target.value === "O") {
      genderVal = "OTHER";
    }
    details.Gender = genderVal;
    setDocGender(genderVal);
  };

  useEffect(() => {
    dispatch(centerlist());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);
  const APIPROFILEDETAILS = APIURL() + "ProfileDetails";
  const APIFINDBYID = APIURL() + "vacancies/findByDocId/";
  // "vacancies/findByDocId/"
  // fetchRecordByID(APIFINDBYID +user.user.id)
  console.log(user, "user id chk");
  useEffect(() => {
    axios.get(APIPROFILEDETAILS + "/" + location.state.state.id).then((res) => {
      let profiledetail = res.data.details[0];
      if (profiledetail) {
        setProfileData(profiledetail);
        // setDetails({ ...details, panno: profiledetail.panno });
        setProfileDetails(profiledetail)
        if (profiledetail.Address) {
          setPAdd(profiledetail.Address)
        }
        if (profiledetail.PinCode) {
          setAddPinDet(profiledetail.PinCode);
        }
        if (profiledetail.City) {
          setAddCity(profiledetail.City);
        }
        if (profiledetail.State) {
          setAddState(profiledetail.State);
        }
        if (profiledetail.aboutdoctor) {
          setAbout(profiledetail.aboutdoctor);
        }
        if (profiledetail.Gender) {
          setDocGender(profiledetail.Gender);
        }
        if (profiledetail.Gender) {
          setValue((profiledetail.Gender).charAt(0));
        }
        if (profiledetail.fathername) {
          setFname(profiledetail.fathername);
        }
        if (profiledetail.birthday) {
          setBirthdayDate(profiledetail.birthday);
        }
        if (profiledetail.adhaarno) {
          setAdhaar(profiledetail.adhaarno);
        }
        if (profiledetail.pgdegree) {
          setpgDeg(profiledetail.pgdegree);
        }
        if (profiledetail.medicaldegree) {
          setMed(profiledetail.medicaldegree);
        }
        if (profiledetail.pgrelevantexp) {
          setRelExp(profiledetail.pgrelevantexp);
        }
        if (profiledetail.pgtotalexp) {
          setTotPgExp(profiledetail.pgtotalexp);
        }
        if (profiledetail.indemnityvalue) {
          setindemnityvalue(profiledetail.indemnityvalue);
        }
        if (profiledetail.mcrcval) {
          setMcrcValue(profiledetail.mcrcval);
        }
        if (profiledetail.mcrdate) {
          setCertIssueDate(profiledetail.mcrdate);
        }
        if (profiledetail.mcrstate) {
          setstateval({ ...stateval, state: profiledetail.mcrstate });
        }
        console.log(details, stateval, birthdaydate, 'detailsdetails')
        // alert("Profile details already filled");
      }
      // console.log(res.data.details[0], "doctor data by id");
    });
  }, []);

  useEffect(() => {
    axios
      .get(APIFINDBYID + location.state.state.id)
      .then((res) => {
        console.log(res, "0000000000");
        setOnboardData(res.data);
      })
      .catch((error) => { });
  }, []);

  const setProfileDetails = async (object = '') => {
    if (object) {
      // var newDetails = { ...details }
      var keys = Object.keys(object)
      var values = Object.values(object)
      for (let i = 0; i < keys.length; i++) {
        if (values[i]) {
          details[keys[i]] = values[i]
        }
      }
      await setDetails(details);
      console.log(details, 'newDetails')
    } else {
      await setDetails(initialForm);
    }
  }

  const [error, setError] = useState({});
  const fileHandler1 = (e) => {
    setmcrc(e.target.files[0]);
    setfilePath(e.target.value);
    setFilename(e.target.files[0].name);
  };
  const mcrcvaluehandler = (e) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      error.medicalcertno = "";
      setError({ ...error });

    }
    else {
      error.medicalcertno = "This is required field.";
      setError({ ...error });
    }
    setMcrcValue(value);
  };
  const issuedatehandler = (value) => {
    console.log(value, "errors_table")
    if (value !== '' || value !== null) {
      error.certIssueDate = "";
      setError({ ...error });
    }
    else {
      error.certIssueDate = "This is required field.";
      setError({ ...error });
    }
    setCertIssueDate(value);
  };
  const APISINGLEDOC = APIURL() + "documents/uploadOne";

  console.log(mcrnValue, "mcrnValue");

  const handleOnboardingDetails = async () => {
    let temp = {};
    console.log(details, "detailsdetailsdetails");
    console.log(stateval.state, "1111");
    console.log(sublaterdays, "22222");
    const formData = new FormData();
    temp.fullname = details.fullname != "" && details.fullname != null ? "" : "This is required field.";
    temp.fathername = fname != "" && fname != null ? "" : "This is required field.";
    temp.mobile = (details.mobile != "" && details.mobile != null) || (location.state.state.mobile != "" && location.state.state.mobile != null) ? "" : "This is required field.";
    temp.email = details.email != "" && details.email != null ? "" : "This is required field.";
    temp.address = pAdd != "" && pAdd != null ? "" : "This is required field.";
    temp.state = addState != "" && addState != null ? "" : "This is required field.";
    temp.city = addCity != "" && addCity != null ? "" : "This is required field.";
    temp.pincode = addpin != "" && addpin != null ? "" : "This is required field.";
    temp.paddress =
      details.PermanentAddress != "" && details.PermanentAddress != null ? "" : "This is required field.";
    temp.pstate = details.PermanentState != "" && details.PermanentState != null ? "" : "This is required field.";
    temp.pcity = details.PermanentCity != "" && details.PermanentCity != null ? "" : "This is required field.";
    temp.pcode =
      details.PermanentPinCode != "" && details.PermanentPinCode != null ? "" : "This is required field.";
    temp.aboutdoctor =
      about != "" && about != null ? "" : "This is required field.";
    temp.bankaccno = details.bankaccno != "" && details.bankaccno != null ? "" : "This is required field.";
    temp.bankname = details.bankname != "" && details.bankname != null ? "" : "This is required field.";
    temp.panno = details.panno != "" && details.panno != null ? "" : "This is required field.";
    temp.adhaarno = details.adhaarno != "" && details.adhaarno != null ? "" : "This is required field.";
    temp.bankifsc = details.bankifsc != "" && details.bankifsc != null ? "" : "This is required field.";
    temp.contractlength = details.contractlength != "" && details.contractlength != null ? "" : "This is required field.";
    temp.indemnityvalue = indemnityvalue != "" && indemnityvalue != null && indemnityvalue >= 500000 ? "" : "This is required field and it should be minimum Rs. 5,00,000/-";
    temp.pgdegree = pgdeg != "" && pgdeg != null ? "" : "This is required field.";
    temp.pgtotalexp = totpgexp != "" && totpgexp != null ? "" : "This is required field.";
    temp.pgrelevantexp = details.pgrelevantexp != "" && details.pgrelevantexp != null ? "" : "This is required field.";
    temp.medicaldegree = med != "" && med != null ? "" : "This is required field.";
    temp.mcrstate = details.mcrstate != "" && details.mcrstate != null ? "" : "This is required field.";
    temp.birthdaydate = birthdaydate != "" && birthdaydate != null ? "" : "This is required field.";
    temp.docGender = docGender != "" && docGender != null ? "" : "This is required field.";
    if (mcrnValue == false) {
      temp.medicalcertno = mcrcValue != "" && mcrcValue != null ? "" : "This is required field.";
      temp.certIssueDate = certIssueDate != "" && certIssueDate != null ? "" : "This is required field.";
      temp.mcrc = mcrc != "" && mcrc != null ? "" : "This is required field.";
    }
    else {
      temp.mcrdate = mcrdate != "" && mcrdate != null ? "" : "This is required field.";
      temp.sublaterdays = sublaterdays != "" && sublaterdays != null ? "" : "This is required field.";
      temp.mcrsubreason = mcrcReasonValue != "" && mcrcReasonValue != null ? "" : "This is required field.";
    }
    await setError(temp);

    console.log(error, "errors for profile");
    if (Object.keys(temp).length) {
      setBackDropOpen(true)
      const isEmpty = Object.values(temp).every((x) => x === "");
      console.log(isEmpty, "rcc");
      if (isEmpty === true) {
        data.designationlist =
          onboardData &&
          onboardData.vacancy &&
          onboardData.vacancy.designationLabel &&
          onboardData.vacancy.designationLabel;
        data.departmentlist =
          onboardData &&
          onboardData.vacancy &&
          onboardData.vacancy.departmentLabel &&
          onboardData.vacancy.departmentLabel;
        data.businessunit =
          onboardData &&
          onboardData.vacancy &&
          onboardData.vacancy.centerNames &&
          onboardData.vacancy.centerNames;
        // var k = moment(
        //   moment(details.doj).add(details.contractlength, "M").calendar()
        // ).format("DD-MM-YYYY");
        // details.agreementexpirydate = k;
        details.mcrsubreason = mcrcReasonValue;
        details.mcrstate = stateval.state;
        if (!mcrnValue) {
          formData.append("certIssueDate", certIssueDate);
          formData.append("doctorId", location.state.state.id);
          formData.append("docValue", mcrcValue);
          formData.append(
            "docTitle",
            "Medical Council Registration Certificate No"
          );
          // formData.append("doctorId", onboardData);
          formData.append("docFile", mcrc);
          formData.append("name", filename);
          formData.append("url", filePath);
          formData.append("state", stateval.state);
          formData.append("sublaterdays", sublaterdays.sublaterdays);
          // var p = moment(moment(certIssueDate).add(60, "M").calendar()).format(
          //   "DD-MM-YYYY"
          // );
          var p = moment(moment(certIssueDate)).format(
            "DD-MM-YYYY"
          );
          formData.append("certExpiryDate", p);
          console.log(formData, "formData");
          postRecord(APISINGLEDOC, formData).then(function (response) {
            // setRefresh(true);
          });
        }
        details.Gender = docGender;
        details.doctorId = location.state.state.id;
        details.mobile = location.state.state.mobile;
        details.indemnityvalue = indemnityvalue;
        details.adhaarno = adhaar;
        details.mcrcval = mcrcValue;
        details.noticeperiod = location.state.state.noticeperiod;
        details.lockinperiod = location.state.state.lockinperiod;
        details.fathername = fname;
        details.Address = pAdd;
        details.City = addCity;
        details.State = addState;
        details.PinCode = addpin;
        details.aboutdoctor = about;
        details.contractlength = location.state.state.contractterm;
        details.pgdegree = pgdeg;
        details.medicaldegree = med;
        details.pgtotalexp = totpgexp;
        details.pgrelevantexp = relExp;
        const postData = {
          data: data,
          user_id: user.user.id,
          role: user.user.roles,
          details: details,
        };
        const obj = {
          table: table,
        };
        console.log(postData, "profile-data");
        if (indemnityvalue < 500000) {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle:
              "Indemnity insurance value should be minimum Rs. 50,00,000/-",
          });
          return;
        }
        axios
          .post(APIURL() + "addqualification/postqual", obj)
          .then((res) => { });

        // postRecord(APIPROFILEDETAILS, postData);
        await axios.post(APIPROFILEDETAILS, postData).then(async (res) => {
          console.log(res, "000000000000000000000");
          if (res.data.message) {
            setConfirmDialog({
              isOpen: true,
              title: "Alert",
              subtitle: res.data.message,
            });
            setBackDropOpen(false)
            return;
          }
          const obj = {
            doctorId: details.doctorId,
            name: location.state.state.name,
            currentAction: agreementState.ag_init.state,
            currentActionBy: details.doctorId,
            nextAction: agreementState.dr_accepted.state,
            remark: "",
            status: 1,
          };
          await axios
            .post(APIURL() + "onboard/agreementAction/", obj)
            .then((res) => { setBackDropOpen(false) });

          await history.push("/UploadJoining");
        })
          .catch((error) => {
            setConfirmDialog({
              isOpen: true,
              title: "Alert",
              subtitle: error,
            });
            setBackDropOpen(false);
          });
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Please fill required fields",
        });
        setBackDropOpen(false);
        return;
      }
    }
  };
  const handleChangeMCRN = (e) => {
    setMcrnValue(e.target.checked);
  };
  const selectedQualValue = (e) => {
    setAddQual(e.target.value);
  };

  const selectAadhaar = (e) => {
    setAdhaar(e.target.value);
    details.adhaarno = e.target.value;
  };

  //   console.log(user.user, "user.user.name");
  console.log(addQual);
  const addTableData = () => {
    console.log(addQual, "addQual");
    if (addQual === "" || addQual == undefined) {
      toast.error("Kindly add qualification name");
      return false;
    }
    let addQualArray = [];
    add.DoctorName = location.state.state.name;
    add.qualification = addQual;
    let addData = {
      ...add,
    };
    addQualArray.push(addData);
    console.log(addQualArray, "addQualArray");
    setTable((prevItems) => [...prevItems, ...addQualArray]);
    setAddQual("");
  };

  console.log(table, "tableee");
  const selectedValueforAddressAll = (event) => {
    const { name, value } = event.target;
    if (name === "PinCode") {
      setAddPinDet(value);
      if (value.length > 0) {
        if (value.length === 6) {
          setBackDropOpen(true);
          axios
            .get("https://api.postalpincode.in/pincode/" + value)
            .then((res) => {
              setDetails({
                ...details,
                ["PinCode"]: value,
                ["State"]: res.data[0].PostOffice[0].State,
                ["City"]: res.data[0].PostOffice[0].District,
              });
              setAddPinDet(value);
              setAddCity(res.data[0].PostOffice[0].District);
              setAddState(res.data[0].PostOffice[0].State);
              setBackDropOpen(false);
              error.pincode = "";
              error.state = "";
              error.city = "";
              setError({ ...error });
            })
            .catch((err) => {
              axios
                .get("https://app.zipcodebase.com/api/v1/search?apikey=9e474a10-b9e5-11ee-89cf-0bfa33dc5bdc&codes=" + value)
                .then((res) => {
                  var pincodeResult = res.data.results[value][0]
                  setAddPinDet(value);
                  setAddCity(pincodeResult.city);
                  setAddState(pincodeResult.state);
                  setBackDropOpen(false);
                  error.pincode = "";
                  error.state = "";
                  error.city = "";
                  setError({ ...error });
                })
                .catch((err) => {
                  error.pincode = "Invalid Pincode.";
                  setError({ ...error });
                  setBackDropOpen(false);
                });
            });
        } else {
          error.pincode = "Invalid Pincode";
          error.state = "";
          error.city = "";
          setError({ ...error });
        }
      } else {
        error.pincode = "This is required field.";
        setError({ ...error });
      }
    }
  };

  
  const checkforIFSCfromindiatoday = (value) => {
    axios
    .get("https://mfapps.indiatimes.com/ET_Calculators/getBankDetailsByIfsc.htm?ifsccode=" + value)
    .then((res) => {
      console.log(res, 'ifsc code result')
      setDetails({
        ...details,
        ["bankifsc"]: value,
        ["bankname"]: res.data.bankDetails.bank,
        ["branchname"]: res.data.bankDetails.branch,
      });
      setBackDropOpen(false);
      error.bankifsc = "";
      setError({ ...error });
    })
    .catch((err) => {
      setDetails({
        ...details,
        ["bankifsc"]: value,
        ["bankname"]: "",
        ["branchname"]: "",
      });
      console.log(err, 'ifsc code error')
      setBackDropOpen(false);
      error.bankifsc = "Invalid ifsc code";
      setError({ ...error });
    });
  }

  const selectedValue = (event) => {
    const { name, value } = event.target;
    // setDetails({ ...details, [name]: value });
    let BankName = "";
    if (name === "fullname") {
      if (value.length > 0) {
        error.fullname = "";

        setError({ ...error });
      } else {
        error.fullname = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "fathername") {
      if (value.length > 0) {
        error.fathername = "";
        setFname(value);
        setDetails({ ...details, ["fathername"]: value });
        setError({ ...error });
      } else {
        error.fathername = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "mobile") {
      var phoneno = /^[1-9]\d{9}$/;
      if (value.length > 0) {
        if (phoneno.test(value)) {
          error.mobile = "";
          setError({ ...error });
        } else {
          error.mobile = "Invalid phone number.";
          setError({ ...error });
        }
      } else {
        error.mobile = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "email") {
      var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value.length > 0) {
        if (re.test(value)) {
          error.email = "";
          setError({ ...error });
        } else {
          error.email = "Invalid email id.";
          setError({ ...error });
        }
      } else {
        error.email = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "Address") {
      if (value.length > 0) {
        setPAdd(value);
        error.address = "";
        setError({ ...error });
      } else {
        error.address = "This is required field.";
        setError({ ...error });
      }
    }
    // if (name === "City") {
    //   if (value.length > 0) {
    //     error.city = "";
    //     setError({ ...error });
    //   } else {
    //     error.city = "This is required field.";
    //     setError({ ...error });
    //   }
    // }
    // if (name === "State") {
    //   if (value.length > 0) {
    //     error.state = "";
    //     setError({ ...error });
    //   } else {
    //     error.state = "This is required field.";
    //     setError({ ...error });
    //   }
    // }
    if (name === "PinCode") {
      if (value.length > 0) {
        if (value.length === 6) {
          setBackDropOpen(true);
          axios
            .get("https://api.postalpincode.in/pincode/" + value)
            .then((res) => {
              setDetails({
                ...details,
                ["PinCode"]: value,
                ["State"]: res.data[0].PostOffice[0].State,
                ["City"]: res.data[0].PostOffice[0].District,
              });
              setBackDropOpen(false);
              error.state = "";
              error.city = "";
              error.pincode = "";
              setError({ ...error });
            })
            .catch((err) => {
              axios
                .get("https://app.zipcodebase.com/api/v1/search?apikey=9e474a10-b9e5-11ee-89cf-0bfa33dc5bdc&codes=" + value)
                .then((res) => {
                  var pincodeResult = res.data.results[value][0]
                  setDetails({
                    ...details,
                    ["PinCode"]: value,
                    ["State"]: pincodeResult.state,
                    ["City"]: pincodeResult.city,
                  });
                  setBackDropOpen(false);
                  error.pincode = "";
                  error.state = "";
                  error.city = "";
                  setError({ ...error });
                })
                .catch((err) => {
                  error.pincode = "Invalid Pincode.";
                  setError({ ...error });
                  setBackDropOpen(false);
                });
            });
        }
      } else {
        error.pincode = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "PermanentAddress") {
      if (value.length > 0) {
        error.paddress = "";
        setError({ ...error });
      } else {
        error.paddress = "This is required field.";
        setError({ ...error });
      }
    }

    // if (name === "PermanentState") {
    //   if (value.length > 0) {
    //     error.pstate = "";
    //     setError({ ...error });
    //   } else {
    //     error.pstate = "This is required field.";
    //     setError({ ...error });
    //   }
    // }

    // if (name === "PermanentCity") {
    //   if (value.length > 0) {
    //     error.pcity = "";
    //     setError({ ...error });
    //   } else {
    //     error.pcity = "This is required field.";
    //     setError({ ...error });
    //   }
    // }

    if (name === "PermanentPinCode") {
      if (value.length > 0) {
        if (value.length === 6) {
          setBackDropOpen(true);
          axios
            .get("https://api.postalpincode.in/pincode/" + value)
            .then((res) => {
              error.pcode = "";
              error.pstate = "";
              error.pcity = "";
              console.log(res.data[0].PostOffice[0], "PermanentPinCode");

              console.log(details, "permanent details before set");
              setDetails({
                ...details,
                ["PermanentPinCode"]: value,
                ["PermanentState"]: res.data[0].PostOffice[0].State,
                ["PermanentCity"]: res.data[0].PostOffice[0].District,
              });
              console.log(details, "permanent details");
              setBackDropOpen(false);
            })
            .catch((error) => {
              axios
                .get("https://app.zipcodebase.com/api/v1/search?apikey=9e474a10-b9e5-11ee-89cf-0bfa33dc5bdc&codes=" + value)
                .then((res) => {
                  var pincodeResult = res.data.results[value][0]
                  setDetails({
                    ...details,
                    ["PermanentPinCode"]: value,
                    ["PermanentState"]: pincodeResult.state,
                    ["PermanentCity"]: pincodeResult.city,
                  });
                  setBackDropOpen(false);
                  error.pcode = "";
                  error.pstate = "";
                  error.pcity = "";
                })
                .catch((err) => {
                  error.pcode = "Invalid Pincode.";
                  setError({ ...error });
                  setBackDropOpen(false);
                });
            });
          setError({ ...error });
          setBackDropOpen(false);
        }
      } else {
        error.pcode = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "aboutdoctor") {
      if (value.length > 0) {
        setAbout(value);
        error.aboutdoctor = "";
        setError({ ...error });
      } else {
        error.aboutdoctor = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "bankaccno") {
      if (value.length > 0) {
        error.bankaccno = "";
        setError({ ...error });
      } else {
        error.bankaccno = "This is required field.";
        setError({ ...error });
      }
    }
    // if (name === "bankname") {
    //   if (value.length > 0) {
    //     error.bankname = "";
    //     setError({ ...error });
    //   } else {
    //     error.bankname = "This is required field.";
    //     setError({ ...error });
    //   }
    // }
    if (name === "panno") {
      var Pan = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
      if (value.length > 0) {
        if (Pan.test(value)) {
          setDetails({ ...details, panno: value });
          error.panno = "";
          setError({ ...error });
        } else {
          error.panno = "Invalid pan number.";
          setError({ ...error });
        }
      } else {
        error.panno = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "adhaarno") {
      if (value.length > 0) {
        if (value.length == 12) {
          error.adhaarno = "";
          details.adhaarno = value;
          setError({ ...error });
        } else {
          error.adhaarno = "Invalid aadhaar number.";
          setError({ ...error });
        }
        setAdhaar(value);
      } else {
        error.adhaarno = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "bankifsc") {
      var IFSC = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      if (value.length > 0) {
        if (IFSC.test(value)) {
          error.bankifsc = "";
          if (value.length === 11) {
            setBackDropOpen(true);
            axios
              .get("https://ifsc.razorpay.com/" + value)
              .then((res) => {
                if(res.status !== 200){
                  checkforIFSCfromindiatoday(value)
                }
                else{
                  setDetails({
                    ...details,
                    ["bankifsc"]: value,
                    ["bankname"]: res.data.BANK,
                    ["branchname"]: res.data.BRANCH,
                  });
                  error.bankifsc = "";
                }
                setBackDropOpen(false);
                
              })
              .catch((err) => {
                checkforIFSCfromindiatoday(value)
              });
          }
          setError({ ...error });
          setBackDropOpen(false);
        } else {
          error.bankifsc = "Invalid ifsc code.";
          setError({ ...error });
        }
      } else {
        error.bankifsc = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "contractlength") {
      if (value.length > 0) {
        error.contractlength = "";
        setError({ ...error });
      } else {
        error.contractlength = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "indemnityvalue") {
      if (value.length > 0) {
        error.indemnityvalue = "";
        setError({ ...error });
      } else {
        error.indemnityvalue = "This is required field and it should be minimum Rs. 5,00,000/-";
        setError({ ...error });
      }
    }
    if (name === "pgdegree") {
      if (value.length > 0) {
        setpgDeg(value);
        error.pgdegree = "";
        setError({ ...error });
      } else {
        error.pgdegree = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "pgtotalexp") {
      if (value.length > 0) {
        setTotPgExp(value);
        error.pgtotalexp = "";
        setError({ ...error });
      } else {
        error.pgtotalexp = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "pgrelevantexp") {
      if (value.length > 0) {
        error.pgrelevantexp = "";
        setRelExp(value)
        setError({ ...error });
      } else {
        error.pgrelevantexp = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "medicaldegree") {
      if (value.length > 0) {
        setMed(value);
        error.medicaldegree = "";
        setError({ ...error });
      } else {
        error.medicaldegree = "This is required field.";
        setError({ ...error });
      }
    }
    console.log(details, "detail-ss");
    setDetails({ ...details, [name]: value });
    console.log(details, name, value, "detail-name");
  };
  var newdatas = [];
  function selectedValueS(e) {
    if (e.target.value != null) {
      error.mcrsubreason = "";
      setError({ ...error });
    }
    else {
      error.mcrsubreason = "This is required field.";
      setError({ ...error });
    }
    setMcrcReasonValue(e.target.value);
  }
  const handleChangeIndemnityExpirydate = (a) => {
    setIndemnityExpirydate(a);
    details.indemnityexpiry = a;
  };

  const handleChangeBirthday = (a) => {
    console.log(a, 'birthdaydate')
    setBirthdayDate(a);
    details.birthday = a;
  };

  const handleMcrcresval = (a) => {
    setmcrcReasonValueDate(a);
    details.mcrdate = a;
  };
  const handleChangeJoining = (a) => {
    setJoiningDate(a);
    details.doj = a;
  };

  // const handleChangeStamp = (a) => {
  //   setStampdate(a);
  //   details.stampdate = a;
  // };

  const handleChangeMCRDate = (e) => {
    // console.log(e.target.value,"errors_table")
    if (e.target.value.length != null && e.target.value.length != 0) {
      error.mcrdate = "";
      setError({ ...error });
    }
    else {
      error.mcrdate = "This is required field.";
      setError({ ...error });
    }
    setMcrDate(e.target.value);
    details.mcrdate = e.target.value;
  };

  const handleChangemaritalStat = (e) => {
    setmaritalStat(e.target.checked);
  };
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  // useEffect(() => {
  //   if (chechBox === "true") {
  //     details.PermanentAddress = pAdd;
  //     details.PermanentState = addState;
  //     details.PermanentCity = addCity;
  //     details.PermanentPinCode = addpin;
  //   } else {
  //     details.PermanentAddress = "";
  //     details.PermanentState = "";
  //     details.PermanentCity = "";
  //     details.PermanentPinCode = "";
  //   }
  // }, [chechBox, details]);

  const handleChangePermanentAdd = (event) => {
    const value = event.target.checked;
    if (value === true) {
      setCheckBox("true");
      details.PermanentAddress = pAdd;
      details.PermanentState = addState;
      details.PermanentCity = addCity;
      details.PermanentPinCode = addpin;
      error.pcode = "";
      error.paddress = "";
      error.pcity = "";
      error.pstate = "";
      setError({ ...error })
    } else {
      setCheckBox("false");
      details.PermanentAddress = "";
      details.PermanentState = "";
      details.PermanentCity = "";
      details.PermanentPinCode = "";
      error.pcode = "This is required field.";
      error.paddress = "This is required field.";
      error.pcity = "This is required field.";
      error.pstate = "This is required field.";
      setError({ ...error })
    }
    setDetails({ ...details, details });

    console.log(details, "details=====>");
  };

  const handleindemnityvalue = (event) => {
    const { name, value } = event.target;
    if (name === "indemnityvalue") {
      if (value.length > 0) {
        if (value > 500000) {
          setindemnityvalue(value);
          error.indemnityvalue = "";
          setError({ ...error });
        }
        else {
          error.indemnityvalue = "This is required field and it should be minimum Rs. 5,00,000/-";
          setError({ ...error });
        }
      } else {
        error.indemnityvalue = "This is required field and it should be minimum Rs. 5,00,000/-";
        setError({ ...error });
      }
    }
    setindemnityvalue(value);
    // details.indemnityvalue = value;
  };

  // const handleChangeChkbox = (list) => {
  //     if (chkitem.includes(list.name)) {
  //       var k = [];
  //       var l = [];
  //       l = chkitemId.filter((z) => z != list.id);
  //       k = chkitem.filter((z) => z != list.name);
  //       setChkitemId(l);
  //       setChkitem(k);
  //     } else {
  //       setChkitem((prevItems) => [...prevItems, list.name]);
  //       setChkitemId((prevItems) => [...prevItems, list.id]);
  //     }
  //   };

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/np/fetchNoticeperiod")
      .then((res) => {
        console.log(res, "res-ponse");
        var n = res.data.data.filter((z) => z.id == location.state.state.noticeperiod);
        setNoticeperiod(n);
      })
      .catch((err) => {
        console.log(err, "err-or");
      });
  }, []);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/lp/fetchLockinperiod")
      .then((res) => {
        console.log(res, "res-ponse");
        var l = res.data.data.filter((z) => z.id == location.state.state.lockinperiod);
        setLockinperiod(l);
      })
      .catch((err) => {
        console.log(err, "err-or");
      });
  }, []);

  console.log(lockinperiod, "lock-inp");
  console.log(onboardData, "onboardDataonboardData");
  const name = <p>Name in Full<span style={{ color: "red" }} > *</span></p>;
  const name0 = <p>Father's Name<span style={{ color: "red" }} > *</span></p>;
  const name1 = <p>Enter Mobile Number<span style={{ color: "red" }} > *</span></p>;
  const name2 = <p>Current Address<span style={{ color: "red" }} > *</span></p>;
  const name3 = <p>City<span style={{ color: "red" }} > *</span></p>;
  const name4 = <p>Permanent Address<span style={{ color: "red" }} > *</span></p>;
  const name5 = <p>About Doctor<span style={{ color: "red" }} > *</span></p>;
  const name6 = <p>Spouse Name<span style={{ color: "red" }} > *</span></p>;
  const name7 = <p>Email Address<span style={{ color: "red" }} > *</span></p>;
  const name8 = <p>State<span style={{ color: "red" }} > *</span></p>;
  const name9 = <p>Pin Code<span style={{ color: "red" }} > *</span></p>;
  const name10 = <p>Date of Birth<span style={{ color: "red" }} > *</span></p>;
  const name11 = <p>Designation<span style={{ color: "red" }} > *</span></p>;
  const name12 = <p>Business Unit(s)<span style={{ color: "red" }} > *</span></p>;
  const name13 = <p>Post PG Degree Total Experience<span style={{ color: "red" }} > *</span></p>;
  const name14 = <p>Post PG Degree Relevant Experience<span style={{ color: "red" }} > *</span></p>;
  const name15 = <p>Medical Degree<span style={{ color: "red" }} > *</span></p>;
  const name16 = <p>Lock in period<span style={{ color: "red" }} > *</span></p>;
  const name17 = <p>Additional Qualification<span style={{ color: "red" }} > </span></p>;
  const name18 = <p>Department<span style={{ color: "red" }} > *</span></p>;
  const name19 = <p>Specific Period of Contract<span style={{ color: "red" }} > *</span></p>;
  const name20 = <p>Department<span style={{ color: "red" }} > *</span></p>;
  const name21 = <p>Existing Indemnity Insurance Value<span style={{ color: "red" }} > *</span></p>;
  const name22 = <p>Highest PG Degree<span style={{ color: "red" }} > *</span></p>;
  const name23 = <p>Notice period<span style={{ color: "red" }} > *</span></p>;
  const name24 = <p>PAN<span style={{ color: "red" }} > *</span></p>;
  const name25 = <p>IFSC Code<span style={{ color: "red" }} > *</span></p>;
  const name26 = <p>Branch Name<span style={{ color: "red" }} > *</span></p>;
  const name27 = <p>Aadhaar Card Number<span style={{ color: "red" }} > *</span></p>;
  const name28 = <p>Bank Name<span style={{ color: "red" }} > *</span></p>;
  const name29 = <p>Bank Account Number<span style={{ color: "red" }} > *</span></p>;



  return (
    <>
      {
        user.user.roles == 4 ? <DoctorSidebar />
          :
          user.user.roles !== 0 ? <Sidebar />
            : <AppSidebar />
      }

      <div className="main-content side-content pt-0">
        <div
          className="container-fluid"
          style={{ margin: "0 auto", backgroundColor: "white" }}
        >
          <div className="inner-body">
            <Navbar />
            <div
              className="container"
              style={{ width: "100%", height: "500vh" }}
            >
              <h2
                style={{
                  backgroundColor: "#810e36",
                  color: "white",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                Personal Information
              </h2>
              <hr />
              <br />
              {(profileData.fathername != null ||
                profileData.fathername != "") &&
                (profileData.Address != null || profileData.Address != "") &&
                (profileData.Gender != null || profileData.Gender != "") &&
                (profileData.State != null || profileData.State != "") &&
                (profileData.adhaarno != null || profileData.adhaarno != "") &&
                (profileData.bankname != null || profileData.bankname != "") &&
                (profileData.bankifsc != null || profileData.bankifsc != "") &&
                (profileData.bankaccno != null || profileData.bankaccno != "") ? (
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    details.birthday = birthdaydate;
                    // details.doj = joiningdate;
                    // details.stampdate = stampdate;
                    details.mcrdate = mcrdate;
                    details.indemnityexpiry = indemnityExpirydate;
                    handleOnboardingDetails();
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label={name}
                        // label="Name in Full"
                        // InputLabelProps={{
                        //   classes: {
                        //     root: classes.cssLabel
                        //   },
                        //   FormLabelClasses: {
                        //     asterisk: classes.labelAsterisk
                        //   }
                        // }}
                        variant="outlined"
                        fullWidth
                        // required
                        name="fullname"
                        value={location.state.state.name}
                        // value={details.fullname}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                        // required

                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.fullname}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name1}
                        // label="Enter Mobile Number"
                        variant="outlined"
                        fullWidth
                        // required
                        name="mobile"
                        // value={details.mobile}
                        value={location.state.state.mobile}
                        disabled
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.mobile}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name2}
                        // label="Current Address"
                        variant="outlined"
                        fullWidth
                        // required
                        name="Address"
                        value={pAdd}
                        //onChange={selectedValue}
                        onChange={(e) => {
                          setPAdd(e.target.value);
                        }}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <AddressIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        multiline
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.address}
                      />
                      <TextField
                        disabled
                        id="outlined-basic"
                        label={name3}
                        // label="City"
                        // required
                        variant="outlined"
                        fullWidth
                        name="City"
                        value={addCity}
                        onChange={selectedValueforAddressAll}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <EmailIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.city}
                      />
                      <div>
                        <FormControl component="fieldset">
                          <FormLabel
                            component="legend"
                            style={{
                              color: "black",
                              fontSize: "13px",
                              marginBottom: "20px",
                            }}
                          ></FormLabel>
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              // value="top"
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={handleChangePermanentAdd}
                                />
                              }
                              label="Same as Current Address?"
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>{" "}
                      </div>
                      <TextField
                        disabled={chechBox === "true"}
                        id="outlined-basic"
                        // label="Permanent Address"
                        label={name4}
                        variant="outlined"
                        fullWidth
                        // required
                        name="PermanentAddress"
                        value={details.PermanentAddress}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px", marginTop: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <AddressIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        multiline
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.paddress}
                      />
                      <TextField
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        fullWidth
                        name="PermanentCity"
                        value={details.PermanentCity}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <EmailIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.pcity}
                      />
                      <TextField
                        id="outlined-basic"
                        // label="About Doctor"
                        label={name5}
                        variant="outlined"
                        fullWidth

                        name="aboutdoctor"
                        value={about}
                        onChange={(e) => {
                          setAbout(e.target.value);
                        }}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <AddressIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        multiline
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.aboutdoctor}
                      />
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          style={{ color: "black", fontSize: "13px" }}
                        ></FormLabel>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={
                              <Checkbox
                                color="primary"
                                checked={maritalStat}
                                onChange={handleChangemaritalStat}
                              />
                            }
                            label="Marital Status(Yes)?"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>{" "}
                      {maritalStat ? (
                        <div>
                          <div className="form-group">
                            <TextField
                              name="husbandname"
                              value={details.husbandname}
                              // label={"Spouse Name"}
                              label={name6}
                              onChange={selectedValue}
                              className={styleClasses.root}
                              variant="outlined"
                              autoComplete="off"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <span>
                                      {details.Gender == "MALE"
                                        ? "Mrs. "
                                        : details.Gender == "FEMALE"
                                          ? "Mr. "
                                          : "Mr / Mrs"}
                                    </span>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} sm={6} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label={name7}
                        // label="Email Address"
                        variant="outlined"

                        fullWidth
                        name="email"
                        value={location.state.state.email}
                        // value={details.email}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.email}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name0}
                        variant="outlined"
                        fullWidth

                        name="fathername"
                        value={fname}
                        // onChange={selectedValue}
                        onChange={(e) => {
                          setFname(e.target.value);
                        }}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.fathername}
                      />

                      <TextField
                        disabled
                        id="outlined-basic"
                        label={name8}
                        // label="State"
                        variant="outlined"
                        fullWidth

                        name="State"
                        value={addState}
                        onChange={selectedValueforAddressAll}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <EmailIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.state}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Pin Code"
                        // label="Pin Code"
                        variant="outlined"
                        fullWidth

                        name="PinCode"
                        inputProps={{ maxLength: 6 }}
                        onInput={(e) => onlyNumbers(e)}
                        value={addpin}
                        // onChange={selectedValue}
                        onChange={selectedValueforAddressAll}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <EmailIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.pincode}
                      />
                      <div className="form-group">
                        <FormControl component="fieldset" className="form-group">
                          <FormLabel component="legend">Gender <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "7px",
                              marginLeft: "6px",
                              marginBottom: "-10px",
                              verticalAlign: "super"

                            }}
                            aria-hidden="true"
                          ></i></FormLabel>
                          <RadioGroup
                            className="w-100"
                            row
                            aria-label="Gender"
                            name="Gender"
                            value={value}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="M"
                              control={<Radio color="#fd7e14" />}
                              label="Male"
                              checked={value == "M" ? true : false}
                            />
                            <FormControlLabel
                              value="F"
                              control={<Radio color="#00963f" />}
                              label="Female"
                              checked={value == "F" ? true : false}
                            />
                            <FormControlLabel
                              value="O"
                              control={<Radio color="#00963f" />}
                              label="Other"
                              checked={value == "O" ? true : false}
                            />
                          </RadioGroup>
                          <div style={{ color: "red", fontSize: "12px" }}>{error.docGender}</div>
                        </FormControl>
                      </div>

                      <TextField
                        id="outlined-basic"
                        // label="State"
                        label={name8}
                        variant="outlined"
                        fullWidth

                        name="PermanentState"
                        value={details.PermanentState}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <EmailIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.pstate}
                      />
                      <TextField
                        disabled={chechBox === "true"}
                        id="outlined-basic"
                        // label="Pin Code"
                        label={name9}
                        variant="outlined"
                        fullWidth

                        name="PermanentPinCode"
                        inputProps={{ maxLength: 6 }}
                        onInput={(e) => onlyNumbers(e)}
                        value={details.PermanentPinCode}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <EmailIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.pcode}
                      />

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          maxDate={(new Date()).setFullYear((new Date()).getFullYear() - 18)}
                          // disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          autoOk
                          label={name10}
                          // label="Date of Birth"
                          // disablePast

                          id="birthday"
                          value={birthdaydate}
                          className={styleClasses.root}
                          onChange={handleChangeBirthday}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          fullWidth
                        />
                      </MuiPickersUtilsProvider>
                      <div style={{ color: "red", fontSize: "12px" }}>{error.birthdaydate}</div>
                    </Grid>
                  </Grid>
                  <hr />
                  <h2
                    style={{
                      backgroundColor: "#810e36",
                      color: "white",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Qualification & Experience
                  </h2>
                  <hr />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
                      <TextField
                        id="outlined-basic"
                        // label="Designation"
                        label={name11}
                        variant="outlined"
                        fullWidth

                        name="designationlist"
                        value={
                          onboardData &&
                          onboardData.vacancy &&
                          onboardData.vacancy.designationLabel &&
                          onboardData.vacancy.designationLabel
                        }
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled
                      />

                      <TextField
                        id="outlined-basic"
                        // label="Business Unit(s)"
                        label={name12}
                        variant="outlined"
                        fullWidth

                        name="businessunit"
                        value={
                          onboardData &&
                          onboardData.vacancy &&
                          onboardData.vacancy.centerNames &&
                          onboardData.vacancy.centerNames
                        }
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled
                      />
                      <br />
                      {/* <>
                                            <FormControl
                                                component="fieldset"
                                                style={{
                                                    marginBottom: ".5rem",
                                                    marginTop: "-.6rem",
                                                }}
                                            >
                                                <h6
                                                    component="legend"
                                                    style={{ color: "black", fontSize: "15px" }}
                                                >
                                                    Business Unit(s) */}
                      {/* </h6> */}
                      {/* <FormGroup aria-label="position" row>
                              {l.map((item) => (
                                <FormControlLabel
                                  value={item.id}
                                  control={<Checkbox color="primary" />}
                                  label={item.name}
                                  labelPlacement="start"
                                  checked={chkitem.includes(item.name)}
                                  onChange={() => {
                                    handleChangeChkbox(item);
                                  }}
                                />
                              ))}
                            </FormGroup> */}
                      {/* </FormControl>
                                        </> */}
                      <TextField
                        id="outlined-basic"
                        label={name13}
                        // label="Post PG Degree Total Experience"
                        variant="outlined"
                        fullWidth

                        name="pgtotalexp"
                        value={totpgexp}
                        onChange={(e) => {
                          setTotPgExp(e.target.value);
                        }}
                        onInput={(e) => onlyNumbers(e)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <span>In Months</span>
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.pgtotalexp}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name14}
                        // label="Post PG Degree Relevant Experience"
                        variant="outlined"
                        fullWidth

                        name="pgrelevantexp"
                        value={relExp}
                        onInput={(e) => onlyNumbers(e)}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <span>In Months</span>
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.pgrelevantexp}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name15}
                        variant="outlined"
                        fullWidth

                        name="medicaldegree"
                        value={med}
                        onChange={(e) => {
                          setMed(e.target.value);
                        }}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.medicaldegree}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name17}
                        variant="outlined"
                        fullWidth
                        // required
                        name="qualification"
                        value={addQual}
                        onChange={selectedQualValue}
                        style={{ marginTop: "20px" }}
                        autoComplete="off"
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label={name18}
                        variant="outlined"
                        fullWidth
                        // required
                        name="departmentlist"
                        value={
                          onboardData &&
                          onboardData.vacancy &&
                          onboardData.vacancy.departmentLabel &&
                          onboardData.vacancy.departmentLabel
                        }
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled
                      />

                      {/* <TextField
                        id="outlined-basic"
                        label={name19}
                        // label="Specific Period of Contract"
                        variant="outlined"
                        fullWidth
                        disabled
                        // required
                        name="contractlength"
                        value={location.state.state.contractterm}
                        // onChange={(e) => {
                        //   setConLen(e.target.value);
                        // }}
                        onInput={(e) => onlyNumbers(e)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       <span>In Months</span>
                        //     </InputAdornment>
                        //   ),
                        // }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.contractlength}
                      /> */}
                      {/* <TextField
                                            id="outlined-basic"
                                            label="Specific Period of Contract"
                                            variant="outlined"
                                            fullWidth
                                            name="contractlength"
                                            value={details.contractlength}
                                            onChange={selectedValue}
                                            style={{ marginBottom: "20px" }}
                                            autoComplete="off"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <span>In Months</span>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        /> */}
                      <TextField
                        id="outlined-basic"
                        label={name21}
                        variant="outlined"
                        fullWidth

                        name="indemnityvalue"
                        value={
                          indemnityvalue ? numberWithCommas(indemnityvalue) : ""
                        }
                        // InputProps={{ inputProps: { min: 500000 } }}
                        // value={details.indemnityvalue ? numberWithCommas(details.indemnityvalue) : ""}
                        onChange={handleindemnityvalue}
                        style={{ marginBottom: "20px" }}
                        onInput={(e) => onlyNumbers(e)}
                        autoComplete="off"
                        InputProps={{
                          inputProps: { min: 500000 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <span>Rs.</span>
                            </InputAdornment>
                          ),
                          inputProps: { min: 500000 },
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.indemnityvalue}
                      />

                      <TextField
                        id="outlined-basic"
                        // label="Highest PG Degree"
                        variant="outlined"
                        fullWidth
                        label={name22}
                        name="pgdegree"
                        value={pgdeg}
                        onChange={(e) => {
                          setpgDeg(e.target.value);
                        }}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.pgdegree}
                      />
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          autoOk
                          label="Proposed Joining Date"
                          // disablePast
                          id="doj"
                          value={joiningdate}
                          onChange={handleChangeJoining}
                          className={styleClasses.root}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          fullWidth
                        />
                      </MuiPickersUtilsProvider> */}

                      <TextField
                        id="outlined-basic"
                        label={name23}
                        variant="outlined"
                        fullWidth
                        disabled

                        name="noticeperiod"
                        value={location.state.state.noticeperiod}
                        // onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <span>In Months</span>
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                      // helperText={error.medicaldegree}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name16}
                        // label="Lock in period"
                        variant="outlined"
                        fullWidth
                        disabled

                        name="lockinperiod"
                        value={location.state.state.lockinperiod}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                      // helperText={error.medicaldegree}
                      />
                      <Button
                        variant="contained"
                        // color="primary"
                        style={{
                          backgroundColor: "#810e36",
                          color: "white",
                          height: "25px",
                          padding: "20px",
                          marginTop: "30px",
                          marginRight: "38px",
                        }}
                        startIcon={<AddIcon />}
                        // fullWidth
                        onClick={addTableData}
                      >
                        ADD
                      </Button>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-dialog"
                          autoOk
                          label="Indemnity insurance - Expiry"
                          // disablePast
                          id="indemnityexpiry"
                          value={indemnityExpirydate}
                          onChange={handleChangeIndemnityExpirydate}
                          className={styleClasses.root}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          fullWidth
                        />
                      </MuiPickersUtilsProvider> */}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
                      {table && table.length > 0 ? (
                        <Paper>
                          {/* <Paper className={tables.root}> */}
                          <MaterialTable
                            columns={[
                              {
                                title: "Doctor Name",
                                field: "DoctorName",
                              },

                              {
                                title: "Qualification",
                                field: "qualification",
                              },
                            ]}
                            data={table}
                            options={{
                              // filtering: true,
                              // sorting: true,
                              pageSize: 2,
                              pageSizeOptions: [2, 4, 6],
                              search: false,
                              // exportButton: true,
                            }}
                            // title=" "
                            title="Additional Qualifications"
                          />
                        </Paper>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <hr />
                  <h2
                    style={{
                      backgroundColor: "#810e36",
                      color: "white",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    Other Information
                  </h2>
                  <hr />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label={name24}
                        variant="outlined"
                        fullWidth
                        name="panno"

                        value={profileData && profileData.panno}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        // disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.panno}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name25}
                        variant="outlined"
                        fullWidth
                        name="bankifsc"
                        // required
                        value={details.bankifsc}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.bankifsc}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name26}
                        variant="outlined"
                        fullWidth
                        // required
                        name="branchname"
                        value={details.branchname}
                        disabled
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.bankifsc}
                      />
                      <div>
                        <label for="pg-degree">
                          <h6>
                            {" "}
                            State of Registration{" "}
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "7px",
                                marginLeft: "6px",
                                verticalAlign: "super",
                              }}
                              aria-hidden="true"
                            ></i>
                          </h6>
                        </label>
                        <select
                          name="mcrstate"
                          id="state"
                          className="form-control"
                          style={{ color: "#000000" }}
                          onChange={selectedState}
                          value={details.mcrstate}
                        // onChange={(e) => setSelectedmcrc(e.target.value)}
                        >
                          <option value="" disabled selected hidden>
                            Select the state of registration
                          </option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadar and Nagar Haveli">
                            Dadar and Nagar Haveli
                          </option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                        <div style={{ color: "red", fontSize: "12px" }}>{error.mcrstate}</div>
                      </div>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          autoOk
                          label="Stamp Date"
                          // disablePast
                          id="stampdate"
                          value={stampdate}
                          onChange={handleChangeStamp}
                          className={styleClasses.root}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          fullWidth
                        />
                      </MuiPickersUtilsProvider> */}

                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-dialog"
                          autoOk
                          label="Medical Council Validity"
                          // disablePast
                          id="mcrdate"
                          value={mcrdate}
                          onChange={handleChangeMCRDate}
                          className={styleClasses.root}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          fullWidth
                        />
                      </MuiPickersUtilsProvider> */}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label={name27}
                        variant="outlined"
                        fullWidth
                        name="adhaarno"
                        // required
                        value={adhaar}
                        inputProps={{ maxLength: 12 }}
                        onInput={(e) => onlyNumbers(e)}
                        onChange={selectedValue}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.adhaarno}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name28}
                        variant="outlined"
                        fullWidth
                        name="bankname"
                        // required
                        value={details.bankname}
                        onChange={selectedValue}
                        disabled
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.bankname}
                      />
                      <TextField
                        id="outlined-basic"
                        label={name29}
                        variant="outlined"
                        fullWidth
                        // required
                        name="bankaccno"
                        value={details.bankaccno}
                        onChange={selectedValue}
                        onInput={(e) => onlyNumbers(e)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          style: { color: "red" },
                        }}
                        helperText={error.bankaccno}
                      />

                      {/* <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">State of registration</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                label="Select the state of registration"
                                                id="noticeperiod"
                                                name="noticeperiod"
                                                // value={noticeperiod}
                                                onChange={selectedDropdownvalue}
                                            >
                                                <MenuItem >Select  the state of registration</MenuItem>
                                                <MenuItem value=" Andaman and Nicobar Islands"> Andaman and Nicobar Islands</MenuItem>
                                                <MenuItem value=" Arunachal Pradesh"> Arunachal Pradesh</MenuItem>
                                                <MenuItem value="Assam">Assam</MenuItem>
                                                <MenuItem value="Bihar">Bihar</MenuItem>
                                                <MenuItem value="Chandigarh">Chandigarh</MenuItem>
                                                <MenuItem value="Chhattisgarh">Chhattisgarh</MenuItem>
                                                <MenuItem value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</MenuItem>
                                                <MenuItem value="Daman and Diu">Daman and Diu</MenuItem>
                                                <MenuItem value="Delhi">Delhi</MenuItem>
                                                <MenuItem value="Lakshadweep">Lakshadweep</MenuItem>
                                                <MenuItem value="Puducherry">Puducherry</MenuItem>
                                                <MenuItem value="Goa">Goa</MenuItem>
                                                <MenuItem value="Gujarat">Gujarat</MenuItem>
                                                <MenuItem value="Haryana">Haryana</MenuItem>
                                                <MenuItem value="Himachal Pradesh">Himachal Pradesh</MenuItem>
                                                <MenuItem value="Jammu and Kashmir">Jammu and Kashmir</MenuItem>
                                                <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                                                <MenuItem value="Karnataka">Karnataka</MenuItem>
                                                <MenuItem value="Kerala">Kerala</MenuItem>
                                                <MenuItem value="Madhya Pradesh">Madhya Pradesh</MenuItem>
                                                <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                                                <MenuItem value="Manipur">Manipur</MenuItem>
                                                <MenuItem value="Meghalaya">Meghalaya</MenuItem>
                                                <MenuItem value="Mizoram">Mizoram</MenuItem>
                                                <MenuItem value="Nagaland">Nagaland</MenuItem>
                                                <MenuItem value="Odisha">Odisha</MenuItem>
                                                <MenuItem value="Punjab">Punjab</MenuItem>
                                                <MenuItem value="Rajasthan">Rajasthan</MenuItem>
                                                <MenuItem value="Sikkim">Sikkim</MenuItem>
                                                <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                                                <MenuItem value="Telangana">Telangana</MenuItem>
                                                <MenuItem value="Tripura">Tripura</MenuItem>
                                                <MenuItem value="Uttar Pradesh">Uttar Pradesh</MenuItem>
                                                <MenuItem value="Uttarakhand">Uttarakhand</MenuItem>
                                                <MenuItem value="West Bengal">West Bengal</MenuItem>
                                            </Select>
                                        </FormControl> */}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sm={12} xl={12}>
                      <Row>
                        <Col md={6}>
                          {/* <label for="pg-degree">
                            <h6>
                              {" "}
                          State of Registration{" "}
                              <i
                                class="fa fa-asterisk"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "6px",
                                }}
                                aria-hidden="true"
                              ></i>
                            </h6>
                          </label>
                          <select
                            name="state"
                            id="state"
                            className="form-control"
                            style={{ color: "#000000" }}
                            onChange={selectedState}
                          // value={selectedmcrc}
                          // onChange={(e) => setSelectedmcrc(e.target.value)}
                          >
                            <option value="" disabled selected hidden>
                              Select the state of registration
                        </option>
                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                            <option value="Andaman and Nicobar Islands">
                              Andaman and Nicobar Islands
                        </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                        </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Dadar and Nagar Haveli">
                              Dadar and Nagar Haveli
                        </option>
                            <option value="Daman and Diu">Daman and Diu</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                        </option>
                            <option value="Jammu and Kashmir">
                              Jammu and Kashmir
                        </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                          </select> */}
                        </Col>
                        {/* <Col md={6}></Col>
                        <Col md={6}>
                          <FormControl component="fieldset">
                            <FormLabel
                              component="legend"
                              style={{ color: "black", fontSize: "13px" }}
                            ></FormLabel>
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value="top"
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={mcrnValue}
                                    onChange={handleChangeMCRN}
                                  />
                                }
                                label="Submit Medical Council Registration Certificate No Later?"
                                labelPlacement="start"
                              />
                            </FormGroup>
                          </FormControl>{" "}
                        </Col> */}
                        <Col md={6}>
                          <div>
                            {!mcrnValue ? (
                              <div>
                                <label for="pg-degree">
                                  <h6 id="MCRC">
                                    {" "}
                                    Medical Council Registration Certificate No <i
                                      class="fa fa-asterisk"
                                      style={{
                                        color: "red",
                                        fontSize: "7px",
                                        marginLeft: "5px",
                                        verticalAlign: "super",
                                      }}
                                      aria-hidden="true"
                                    ></i>
                                  </h6>
                                </label>
                                <div
                                  className="form-group"
                                  style={{
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <label style={{
                                    fontSize: "smaller",
                                    marginTop: "-9px",

                                  }}
                                  >
                                    Certificate No
                                    <input
                                      type="text"
                                      value={mcrcValue}
                                      id="medicalcertno"
                                      onChange={mcrcvaluehandler}
                                      // onChange={selectedValue}
                                      style={{ color: "#000000", width: "120px" }}
                                      className="form-control"
                                      placeholder="Medical Council Registration Certificate No"
                                    />
                                    <div style={{ color: "red", fontSize: "12px", width: "120px" }}>{error.medicalcertno}</div>
                                  </label>
                                  <label
                                    style={{
                                      fontSize: "smaller",
                                      marginTop: "-9px",
                                    }}
                                  >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <KeyboardDatePicker
                                        margin="normal"
                                        // disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        minDate={new Date()}
                                        autoOk
                                        label={'Expiry Date'}
                                        // label="Date of Birth"
                                        // disablePast

                                        id="certIssueDate"
                                        value={certIssueDate}
                                        className={styleClasses.root}
                                        onChange={issuedatehandler}
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                        fullWidth
                                      />
                                    </MuiPickersUtilsProvider>
                                    <div style={{ color: "red", fontSize: "12px" }}>{error.certIssueDate}</div>
                                  </label>
                                  <label
                                    style={{
                                      fontSize: "smaller",
                                      marginTop: "-9px",
                                    }}
                                  >
                                    Certificate:
                                    <input
                                      type="file"
                                      id={2}
                                      name="mcrc"
                                      onChange={fileHandler1}
                                      className="form-control"
                                    />
                                    <div style={{ color: "red", fontSize: "12px" }}>{error.mcrc}</div>
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <label for="pg-degree">
                                  <h6>
                                    {" "}
                                    Reason and Date for Later Submission{" "} <i
                                      class="fa fa-asterisk"
                                      style={{
                                        color: "red",
                                        fontSize: "7px",
                                        marginLeft: "6px",
                                        verticalAlign: "super",
                                      }}
                                      aria-hidden="true"
                                    ></i>
                                  </h6>
                                </label>
                                <div className="form-group">
                                  <label
                                    style={{
                                      fontSize: "smaller",
                                      marginTop: "-9px",
                                    }}>
                                    Reason:
                                    <div style={{ color: "red", fontSize: "12px" }}>{error.mcrsubreason}</div>
                                    <textarea
                                      value={mcrcReasonValue}
                                      id="mcrsubreason"
                                      onChange={selectedValueS}
                                      style={{ color: "#000000", maxHeigth: '38px' }}
                                      className="form-control"
                                      placeholder="Reason for Later Submission"
                                    />
                                  </label>
                                  <label
                                    style={{
                                      fontSize: "smaller",
                                      marginTop: "-9px",
                                    }}>
                                    Duration:
                                    <div style={{ color: "red", fontSize: "12px" }}>{error.sublaterdays}</div>
                                    <select
                                      className="form-control"
                                      style={{ color: "black", margin: ".2rem" }}
                                      id="sublaterdays"
                                      name="sublaterdays"
                                      onChange={selectedSub}
                                    >
                                      <option value="0" disabled selected hidden>Late Duration</option>
                                      <option value="30 days">30 days</option>
                                      <option value="45 days">45 days</option>
                                      <option value="60 days">60 days</option>
                                      <option value="90 days">90 days</option>
                                    </select>
                                  </label>
                                  <label
                                    style={{
                                      fontSize: "smaller",
                                      marginTop: "-9px",
                                    }}>
                                    Date:
                                    <div style={{ color: "red", fontSize: "12px" }}>{error.mcrdate}</div>
                                    <input
                                      type="date"
                                      value={mcrdate}
                                      id="mcrdate"
                                      // onChange={(e) =>
                                      //   setCertIssueDate(e.target.value)
                                      // }
                                      onChange={handleChangeMCRDate}
                                      style={{ color: "#000000", margin: "5px" }}
                                      className="form-control"
                                    /></label>
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Grid>
                  </Grid>
                  <hr />
                  <br />

                  <Button
                    variant="contained"
                    style={{
                      float: "Right",
                      margin: "2rem",
                      backgroundColor: "#810e36",
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                  {/* <Button variant="contained" onClick={Updatequalification} style={{ float: "Right", margin: "2rem" }}>Update</Button> */}
                </form>
              ) : (
                <>
                  <Paper>
                    {/* <Typography>{"already filled"}</Typography> */}
                    <EditProfiledata profileData={profileData} />
                  </Paper>
                </>
              )}

              <br />
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      // onClick={handleClose}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img src={c9gif} alt="loading..." />
      </Backdrop>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
